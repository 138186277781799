import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  ContextMenuAction,
  Ishtar365CommunicationService,
  MyDateAdapterOptions,
  MyDateLocale,
  SideBarAction,
  TopLayoutComponent,
} from 'processdelight-angular-components';
import { license$, translations$ } from './core/data/data.observables';
import { Subject, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core.module';
import { StartUpService } from './core/services/startup.service';
import { UserLicenseInfo } from './core/domain/models/user-license-info.model';
import { Settings } from 'luxon';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, CommonModule, CoreModule, TopLayoutComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IshtarTime';

  orgLogo$ = license$.pipe(map((o) => o?.logo));
  appLogo = environment.cdnAppIconUrl.replace('color', 'Groen_Blauw');

  userName$ = license$.pipe(map((u) => u?.name));
  userPhoto$ = license$.pipe(map((u) => u?.photo));

  navColor$ = license$.pipe(map((u) => u?.navColor));
  navContrast$ = license$.pipe(map((u) => u?.navContrast));

  buttonActions: ContextMenuAction<unknown>[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];
  actions: SideBarAction[] = [
    new SideBarAction({
      title: 'Home',
      icon: 'home',
      iconOutline: true,
      route: ['home'],
    }),
  ];

  destroy$ = new Subject<void>();

  constructor(
    private startup: StartUpService,
    private ishtarCommunicationsService: Ishtar365CommunicationService,
    private router: Router
  ) {}

  isAdmin$ = license$.pipe(
    map((l) =>
      l?.licenses.some((license) => {
        return (
          license.productName === 'Ishtar.Time' && license.isAdmin === true
        );
      })
    )
  );

  ngOnInit() {
    Settings.defaultLocale = 'nl-BE';

    this.ishtarCommunicationsService.language$
      .pipe(takeUntil(this.destroy$))
      .subscribe((l: string) => {
        if (license$.value === undefined) return;
        this.startup.getTranslations(l).subscribe();
        license$.next({ ...license$.value, language: l } as UserLicenseInfo);
      });

    this.isAdmin$.pipe(takeUntil(this.destroy$)).subscribe((isAdmin) => {
      if (isAdmin) {
        const actionExists = this.iconActions.some(action => action.icon === 'settings');
        if (!actionExists)
        this.iconActions.push(
          new ContextMenuAction<unknown>({
            label: translations$.asObservable().pipe(map((t) => t.settings)),
            icon: 'settings',
            iconOutline: true,
            action: () => this.router.navigate(['settings/time-sort-admin']),
          })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
