import { createAction, props } from '@ngrx/store';
import { LoadTimeInterval } from '../../domain/models/load-time-interval';
import { TimeRegistration } from '../../domain/models/time-registration.model';

export const getTimeRegistrations = createAction(
  '[TimeRegistration] Get time registrations',
  props<{
    userIds: string[];
    loadTimeInterval: LoadTimeInterval;
    callback?: () => void;
  }>()
);

export const getTimeRegistrationsResolved = createAction(
  '[TimeRegistration] Get time registrations resolved',
  props<{
    result: TimeRegistration[];
    loadTimeInterval: LoadTimeInterval;
  }>()
);

export const removeTimeRegistrations = createAction(
  '[TimeRegistration] Remove time registrations',
  props<{
    ishtarTimeRegistrationIds: string[];
    callback?: () => void;
  }>()
);

export const removeTimeRegistrationsResolved = createAction(
  '[TimeRegistration] Remove time registration resolved',
  props<{ ishtarTimeRegistrationIds: string[] }>()
);

export const updateTimeRegistration = createAction(
  '[TimeRegistration] Update time registration',
  props<{
    timeRegistrations: TimeRegistration[];
    callback?: (timeRegistrations: TimeRegistration[]) => void;
  }>()
);

export const updateTimeRegistrationResolved = createAction(
  '[TimeRegistration] Update time registration resolved',
  props<{ updatedTimeRegistrations: TimeRegistration[] }>()
);

export const updateTimeRegistrationFailed = createAction(
  '[TimeRegistration] Update time registration failed',
  props<{ error: any }>()
);

export const addTimeRegistration = createAction(
  '[TimeRegistration] Add time registration',
  props<{
    timeRegistrations: TimeRegistration[];
    callback?: (timeRegistrations: TimeRegistration[]) => void;
  }>()
);

export const addTimeRegistrationResolved = createAction(
  '[TimeRegistration] Add time registration resolved',
  props<{ addedTimeRegistrations: TimeRegistration[] }>()
);
