import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  CalendarComponent,
  CalendarItem,
  MicrosoftAuthenticationService,
  ContextMenuAction,
  Filter,
  LoaderService,
  AADUser,
  GroupUser,
  getTodayInUTC,
  DateStartEnd,
} from 'processdelight-angular-components';
import { LicenseFacade } from '../core/store/license.facade';
import { CalendarItemFacade } from '../core/store/calendar-item/calendar-item.facade';
import {
  Observable,
  Subject,
  combineLatest,
  filter,
  first,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { TeamCalendarItem } from '../core/domain/models/team-calendar-item';
import {
  appName$,
  endDateView$,
  publicHolidays$,
  startDateView$,
  translations$,
} from '../core/data/data.observables';
import { CalendarColors } from '../core/utils/constants';
import { DateUtilsService } from '../core/utils/date-utils.service';
import { PublicHolidayModel } from '../core/domain/models/public-holiday.model';
import { RegistrationDetailsComponent } from './registration-details/registration-details.component';
import { TimeRegistrationFacade } from '../core/store/time-registration/time-registration.facade';
import { license$ } from '../core/data/data.observables';
import { TimeRegistration } from '../core/domain/models/time-registration.model';
import { DeletePopupComponent } from '../configuration/shared/delete-popup/delete-popup.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { GroupFacade } from '../core/store/group/group.facade';
import { TimeSortFacade } from '../core/store/time-sort/time-sort.facade';
import { TimeSort } from '../core/domain/models/time-sort';
import { DateTime } from 'luxon';
import { DateTimeStartEnd } from '../core/domain/models/date-time-start-end';

@Component({
  standalone: true,
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  imports: [
    CommonModule,
    CalendarComponent,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
  ]
})
export class CalendarIshtarTimeComponent implements OnInit, OnDestroy {
  translations = translations$.value;
  destroy$ = new Subject<void>();

  userControl = new FormControl([] as string[]);

  license = license$.value;
  appName = appName$.value;

  timeRegistrationsFilters: Filter[] = [];
  absencesFilters: Filter[] = [];

  timeRegistrations: TimeRegistration[] = [];
  calendarItemsOoO: TeamCalendarItem[] = [];

  loadTimeInterval:DateStartEnd=new DateStartEnd();

  users: AADUser[] = [];
  filteredUsers: AADUser[]|undefined
  groups: GroupUser[] = [];
  timeSorts: TimeSort[] = [];

  calendarHeight = 1240;
  hourBlocks = 2;
  totalAmountOfHours = '';

  navDate = getTodayInUTC();
  currentView = 'week';

  isManager = false;
  isFirstLoading = true;

  get userControlVal():string[] {
    return this.userControl.value as string[];
  }

  @ViewChild('userFilter')
  userDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('kalender') kalender!: CalendarComponent;

  @Input() calenderItems: CalendarItem[] = [];
  @Input() hideTopBar = false;
  @Input() allowItemCollision = true;

  @Output() monthChanged = new EventEmitter<{
    startDate: DateTime;
    endDate: DateTime;
  }>();
  @Output() addCalenderItem = new EventEmitter<{
    startDate: DateTime;
    endDate: DateTime;
  }>();
  @Output() editCalenderItem = new EventEmitter<CalendarItem>();

  constructor(
    private readonly licenseFacade: LicenseFacade,
    private readonly calendarItemOoOFacade: CalendarItemFacade,
    private readonly loaderService: LoaderService,
    private readonly timeRegistrationFacade: TimeRegistrationFacade,
    private readonly timeSortFacade: TimeSortFacade,
    private readonly groupFacade: GroupFacade,
    private readonly dateUtils: DateUtilsService,
    private msal: MicrosoftAuthenticationService,
    private detailsDialog: MatDialog,
    private deleteDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadTimeInterval.end=DateTime.now().set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    this.loadTimeInterval.start= DateTime.now().minus({months:2}).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.loadGroups();
    this.loadPublicHolidays();
    this.loadIshtarSorts();
  
      if (this.msal.userId) {
          this.userControl.patchValue([this.msal.userId])
          this.timeRegistrationsFilters = [];
          this.absencesFilters = [];
          this.calenderItems = this.calenderItems.filter(
            (c) => !this.calendarItemsOoO.some((OoO) => OoO.id === c.id)
          );
          this.calenderItems = this.calenderItems.filter(
            (c) => !this.timeRegistrations.some((t) => t.id === c.id)
          );

          this.loadAbsences(this.msal.userId);
        }

    this.userControl.valueChanges.subscribe((newValue) => {
      if (newValue)
        this.initTimeRegistrations(newValue);
    });
  }

  private initTimeRegistrations(userIds: string[]) {
    this.timeRegistrations = [];
    this.timeRegistrationFacade.timeRegistrations$
      .pipe(
        takeUntil(this.destroy$),
        map((timeRegistrations) => timeRegistrations.filter((x) => userIds.includes(x.userId!))
        ),
        first()
      )
      .subscribe((timeRegistrations) => {
        this.timeRegistrations = timeRegistrations;
        if (this.timeRegistrations && this.isFirstLoading) {
          this.initTotalHours(this.currentView);
          this.isFirstLoading = false;
        }else
          setTimeout(() => {
            this.calculateTotalHours(startDateView$.value, endDateView$.value);
          }, 0);
        this.handleTimeRegistrations(this.timeRegistrations);

      });

  }

  addItem(event: { startDate: DateTime; endDate: DateTime; fullDay: boolean }) {
    // const originalStartDate = this.getOriginalDate(event.startDate);
    // const originalEndDate = this.getOriginalDate(event.endDate);
    this.addTimeRegistration(event.startDate, event.endDate);
  }

  contextBlockMenuActionsFn = (item: DateTime) => {
    // const originalDate = this.getOriginalDate(item);

    return [
      new ContextMenuAction({
        label: this.getTranslation('add'),
        icon: 'add',
        action: () => this.addTimeRegistration(item, item),
      }),
    ];
  };

  private getCreatedOn(timeRegistrationItem: TimeRegistration) {
    const createdOn = timeRegistrationItem.createdOn
      ? DateTime.fromISO(timeRegistrationItem.createdOn.toString(), {
          zone: 'utc',
        })
      : undefined;

    return createdOn?.toFormat('dd/MM/yyyy HH:mm');
  }

  contextItemMenuActionsFn = (item: CalendarItem) => {
    // const originalStartDate = item.startDate
    //   ? this.getOriginalDate(item.startDate)
    //   : undefined;
    // const originalEndDate = item.endDate
    //   ? this.getOriginalDate(item.endDate)
    //   : undefined;

    const timeRegistrationItem = this.timeRegistrations.find(
      (t) => t.id === item.id && t.title === item.title
    );
    if (timeRegistrationItem && timeRegistrationItem.title) {
      const titleSnippet =
        timeRegistrationItem.title.length > 50
          ? timeRegistrationItem.title.substring(0, 50) + '...'
          : timeRegistrationItem.title;

      const newTimeRegistrationItem = {
        ...timeRegistrationItem,
        title: this.getCreatedOn(timeRegistrationItem) + ' ' + titleSnippet,
      };

      return [
        new ContextMenuAction({
          label: this.getTranslation('edit'),
          icon: 'edit',
          action: () =>
            this.detailsDialog
              .open(RegistrationDetailsComponent, {
                autoFocus: false,
                disableClose: true,
                data: {
                  selectedUserIds: [timeRegistrationItem.userId],
                  timeRegistrationItem,
                  startDate: item.startDate, //originalStartDate,
                  endDate: item.endDate, //originalEndDate,
                  timeRegistrations: this.timeRegistrations.slice(),
                },
                maxWidth: '98vw',
                maxHeight: '90vh',
              })
              .afterClosed()
              .subscribe((timeRegistration) => {
                if (timeRegistration) {
                  this.initTimeRegistrations(this.userControlVal)
                  setTimeout(() => {
                    this.kalender.scrollToItem(timeRegistration?.id);
                  }, 0);
                  this.calculateTotalHours(
                    startDateView$.value,
                    endDateView$.value
                  );
                }
              }),
        }),
        new ContextMenuAction({
          label: this.getTranslation('copy'),
          icon: 'copy',
          action: () =>
            this.detailsDialog
              .open(RegistrationDetailsComponent, {
                autoFocus: false,
                disableClose: true,
                data: {
                  selectedUserIds:  timeRegistrationItem.userId,
                  copyMode: true,
                  timeRegistrationItem,
                  startDate: item.startDate, //originalStartDate,
                  endDate: item.endDate, //originalEndDate,
                  timeRegistrations: this.timeRegistrations.slice(),
                },
                maxWidth: '98vw',
                maxHeight: '90vh',
              })
              .afterClosed()
              .subscribe((timeRegistration) => {
                if (timeRegistration) {
                  this.initTimeRegistrations(this.userControlVal)
                  setTimeout(() => {
                    this.kalender.scrollToItem(timeRegistration?.id);
                  }, 0);
                  this.calculateTotalHours(
                    startDateView$.value,
                    endDateView$.value
                  );
                }
              }),
        }),
        new ContextMenuAction({
          label: this.getTranslation('delete'),
          icon: 'delete',
          action: () =>
            this.deleteDialog
              .open(DeletePopupComponent, {
                autoFocus: false,
                data: {
                  timeRegistration: newTimeRegistrationItem,
                },
                disableClose: true,
              })
              .afterClosed()
              .subscribe((result) => {
                if (result == 'submit') {
                  if (timeRegistrationItem?.id) {
                    this.timeRegistrationFacade.removeTimeRegistration([
                      timeRegistrationItem.id,
                    ]);
                    this.timeRegistrations = this.timeRegistrations.filter(
                      (t) => t.id !== timeRegistrationItem.id
                    );
                    this.calenderItems = this.calenderItems.filter(
                      (item) => item.id !== timeRegistrationItem.id
                    );
                    const timeRegistrationDeletedMessage = this.getTranslation(
                      'timeRegistrationDeleted'
                    );
                    this._snackBar
                      .open(timeRegistrationDeletedMessage, 'X', {
                        panelClass: 'app-notification-success',
                      })
                      ._dismissAfter(3000);
                    this.calculateTotalHours(
                      startDateView$.value,
                      endDateView$.value
                    );
                  }
                }
              }),
        }),
      ];
    }
    return [];
  };

  getUserValue(userId: string) {
    return (
      this.users.find((u) => userId === u?.id?.toString())?.displayName || ''
    );
  }


  onViewChanged(event: {
    view: string;
    startDate: DateTime;
    endDate: DateTime;
  }) {
    startDateView$.next(event.startDate);
    endDateView$.next(event.endDate);
    this.calculateTotalHours(startDateView$.value, endDateView$.value);
  }

  dateChanged(event: { startDate: DateTime; endDate: DateTime }) {
    const loadingMessage = this.getTranslation('loadingData');
    startDateView$.next(event.startDate);
    endDateView$.next(event.endDate);
    const filters = this.filteredUsers!.map(user =>
      new Filter({
        columnName: 'UserId',
        value: user.id,
        operator: 'eq'
      }));
    if (event.startDate < this.loadTimeInterval.start!) {
      this.loadTimeInterval = new DateStartEnd()
      this.loadTimeInterval.end = event.endDate;
      this.loadTimeInterval.start = event.startDate.minus({ months: 2 })

      this.loaderService.startLoading(
        loadingMessage,
        () =>
          new Observable((observer) => {
            this.timeRegistrationFacade.getTimeRegistrations$(filters, this.loadTimeInterval)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              setTimeout(() => {
                this.initTimeRegistrations(this.userControlVal)
              }, 0);
              observer.next();
              observer.complete()
            })
          })
      )
    }
    else if (event.endDate > this.loadTimeInterval.end!) {
      this.loadTimeInterval = new DateStartEnd()
      this.loadTimeInterval.end = event.endDate?.plus({ months: 2 });
      this.loadTimeInterval.start = event.startDate
      this.loaderService.startLoading(
        loadingMessage,
        () =>
          new Observable((observer) => {
            this.timeRegistrationFacade.getTimeRegistrations$(filters, this.loadTimeInterval)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              setTimeout(() => {
                this.initTimeRegistrations(this.userControlVal)
              }, 0);
              observer.next();
              observer.complete()
            })
          })
      )
    }
    else
      this.calculateTotalHours(startDateView$.value, endDateView$.value);
  }

  calculateTotalHours(
    startDate: DateTime | undefined,
    endDate: DateTime | undefined
  ): void {
    let totalMinutes = 0;
    const startView = startDate?.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const endView = endDate?.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    //startDate?.setHours(0, 0, 0, 0);
    //endDate?.setHours(23, 59, 59, 999);

    this.timeRegistrations?.forEach((registration) => {
      const startDate = registration.startDate
        ? DateTime.fromISO(registration.startDate.toString(), { zone: 'utc' })
        : undefined;
      const endDate = registration.endDate
        ? DateTime.fromISO(registration.endDate.toString(), { zone: 'utc' })
        : undefined;

      if (
        startDate &&
        startView &&
        startDate >= startView &&
        endDate &&
        endView &&
        endDate <= endView
      ) {
        const durationMs = endDate.valueOf() - startDate.valueOf();
        const durationMinutes = durationMs / 60000;
        totalMinutes += durationMinutes;
      }
    });

    const totalHours = totalMinutes / 60;
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    this.totalAmountOfHours = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  itemChangedByDragOrResize(event: CalendarItem) {
    const timeRegistrationDragged = this.timeRegistrations.find(
      (t) => t.id === event.id
    );
    if (timeRegistrationDragged) {
      // const originalStartDate = event.startDate
      //   ? this.getOriginalDate(event.startDate)
      //   : undefined;
      // const originalEndDate = event.endDate
      //   ? this.getOriginalDate(event.endDate)
      //   : undefined;

      const timeRegistrationToUpdate = new TimeRegistration({
        id: timeRegistrationDragged?.id,
        title: timeRegistrationDragged?.title,
        startDate: event.startDate,
        // this.getOriginalDate(event.startDate)
        //   .toString()
        //   .split(' GMT')[0],
        endDate: event.endDate,
        // this.getOriginalDate(event.endDate)
        //   .toString()
        //   .split(' GMT')[0],
        user: timeRegistrationDragged?.user,
        projectId: timeRegistrationDragged?.projectId,
        dummyProject: timeRegistrationDragged?.dummyProject,
        timeSortId: timeRegistrationDragged?.timeSortId,
        taskId: timeRegistrationDragged?.taskId,
      });

      this.detailsDialog
        .open(RegistrationDetailsComponent, {
          autoFocus: false,
          disableClose: true,
          data: {
            selectedUserId: timeRegistrationDragged.userId,
            timeRegistrationItem: timeRegistrationToUpdate,
            startDate: event.startDate, //originalStartDate,
            endDate: event.endDate, //originalEndDate,
            timeRegistrations: this.timeRegistrations.slice(),
          },
          maxWidth: '98vw',
          maxHeight: '90vh',
        })
        .afterClosed()
        .subscribe((timeRegistration) => {
          if (timeRegistration) {
            this.calenderItems = this.calenderItems.filter(
              (item) => item.id !== event.id
            );
            this.calenderItems = [...this.calenderItems, event];
            const timeRegistrationUpdatedMessage = this.getTranslation(
              'timeRegistrationUpdated'
            );
            this._snackBar
              .open(timeRegistrationUpdatedMessage, 'X', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            setTimeout(() => {
              this.kalender.scrollToItem(timeRegistration?.id);
            }, 0);
            this.calculateTotalHours(startDateView$.value, endDateView$.value);
          }
        });
    }
  }

  getTranslation(label: string | undefined) {
    if (label) {
      return translations$.value[label] ?? label;
    }
  }

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  onUsersDropdownClick() {
    setTimeout(() => {
      this.userDropdown?.nativeElement.focus();
    }, 0);
  }

  handlePublicHolidays(publicHolidays: PublicHolidayModel[]): void {
    publicHolidays.forEach((publicHoliday) => {
      if (publicHoliday.holidayDate) {
        const holidayDate = DateTime.fromISO(
          publicHoliday.holidayDate.toString(),
          { zone: 'utc' }
        );
        const startDateTime = holidayDate.set({
          hour: 9,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const endDateTime = holidayDate.set({
          hour: 17,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        // const endDateTime = new Date(startDateTime);
        // endDateTime.setTime(startDateTime.valueOf() + (endTime - startTime));

        const calendarItem: CalendarItem = {
          id: publicHoliday.holidayType?.concat(holidayDate.toISO()!),
          startDate: startDateTime, //this.getDateWithoutOffset(startDateTime),
          endDate: endDateTime, //this.getDateWithoutOffset(endDateTime),
          title: publicHoliday.holidayType,
          accentColor: CalendarColors.Orange,
          backgroundColor: CalendarColors.Orange,
          isDraggable: false,
          contextMenuDisabled: true,
        };

        const itemIsOnCalendar = this.calenderItems.find(
          (item) =>
            item.id === calendarItem.id && item.title === calendarItem.title
        );

        if (!itemIsOnCalendar) {
          this.calenderItems = [...this.calenderItems, calendarItem];
        }
      }
    });
  }

  handleCalendarItemsOoO(calendarItems: TeamCalendarItem[]): void {
    calendarItems.forEach((ooOAbsence) => {
      const startDate = ooOAbsence.startDate
        ? DateTime.fromISO(ooOAbsence.startDate.toString(), { zone: 'utc' })
        : undefined;
      const endDate = ooOAbsence.endDate
        ? DateTime.fromISO(ooOAbsence.endDate.toString(), { zone: 'utc' })
        : undefined;

      const calendarItem: CalendarItem = {
        id: ooOAbsence.id,
        startDate,
        endDate,
        title:
          ooOAbsence.vacationReason +
          (ooOAbsence.vacationType?.title
            ? ' - ' + ooOAbsence.vacationType?.title
            : ''),
        backgroundColor: CalendarColors.LightGray,
        accentColor: CalendarColors.LightGray,
        isDraggable: false,
        contextMenuDisabled: true,
      };

      const itemIsOnCalendar = this.calenderItems.find(
        (item) =>
          item.id === calendarItem.id && item.title === calendarItem.title
      );

      if (!itemIsOnCalendar) {
        this.calenderItems = [...this.calenderItems, calendarItem];
      }
    });
  }

  loadGroups(): void {
    const loadingMessage = this.getTranslation('loadingData');
    this.loaderService.startLoading(loadingMessage, () =>
      this.groupFacade.groups$.pipe(filter((g) => !!g))
    );

    this.loaderService.startLoading(
      loadingMessage,
      () =>
        new Observable((observer) => {
          this.groupFacade.groups$
            .pipe(
              takeUntil(this.destroy$),
              switchMap((groups) =>
                groups?.length
                  ? (this.groups = groups)
                  : this.groupFacade.getGroups$()
              ),
              take(2)
            )
            .subscribe(() => {
              const ownedGroups = this.groups
                ?.slice()
                .filter((g) =>
                  g.group?.owners?.find((o) => o?.id === this.msal.userId)
                );
              if (ownedGroups?.length > 0) {
                this.isManager = true;

                ownedGroups.forEach((group) => {
                  if (group.group?.members) {
                    this.users = this.users
                      .filter(Boolean)
                      .concat(group.group?.members);
                  }
                }

              );

                const managerUser = ownedGroups[0]?.group?.owners.find(
                  (o) => o?.id === this.msal.userId
                );
                if (managerUser) {
                  this.users = [...this.users.filter(Boolean), managerUser];
                }
                this.filteredUsers =this._userFilter();
                this.loadTimeRegistrationsAndUserSkills(this.filteredUsers!.map(x=>x.id))
              } else {
                this.isManager = false;
                this.filteredUsers=[new AADUser({id:this.msal.userId})]
                this.loadTimeRegistrationsAndUserSkills([this.msal.userId])
              }
              observer.next();
              observer.complete();
            });
        })
    );
  }
 
  loadAbsences(selectedPersonId: string): void {
    this.licenseFacade.hasIshtarOoOLicense$
      .pipe(takeUntil(this.destroy$))
      .subscribe((hasOoOLicense) => {
        if (hasOoOLicense) {
          const statusFilterItem = new Filter({
            columnName: 'ApprovalStatus/Status',
            value: this.getTranslation('approved'),
          });
          const userFilterItem = new Filter({
            columnName: 'Users',
            value: selectedPersonId,
          });
          const vacantionTypeFilterItem = new Filter({
            columnName: 'VacationType/Name',
            value: this.getTranslation('workFromHome'),
          });

          this.absencesFilters.push(statusFilterItem);
          this.absencesFilters.push(userFilterItem);
          this.absencesFilters.push(vacantionTypeFilterItem);

          this.loadIshtarOoOAbsences(selectedPersonId);
        }
      });
  }

  private loadIshtarSorts() {
    this.timeSortFacade.timeSorts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((timeSorts) => {
        if (timeSorts) {
          this.timeSorts = timeSorts;
        }
      });
  }

  loadIshtarOoOAbsences(selectedPersonId: string): void {
    const loadingMessage = this.getTranslation('loadingOoOAbsences');
    this.loaderService.startLoading(
      loadingMessage,
      () =>
        new Observable((observer) => {
          this.calendarItemsOoO = [];
          this.calendarItemOoOFacade
            .calendarItems$(selectedPersonId)
            .pipe(
              takeUntil(this.destroy$),
              switchMap((calendarItems) =>
                calendarItems?.length
                  ? (this.calendarItemsOoO = calendarItems)
                  : this.calendarItemOoOFacade.getCalendarItems$(
                      selectedPersonId,
                      this.absencesFilters
                    )
              ),
              take(2)
            )
            .subscribe(() => {
              this.handleCalendarItemsOoO(this.calendarItemsOoO);
              observer.next();
              observer.complete();
            });
        })
    );
  }

  private _userFilter(): AADUser[] | undefined {
    const uniqueUserIds = new Set<string>();
    const distinctUsers = this.users?.filter((u) => {
      const userId = u?.id.toString() ?? 'x';
      if (!uniqueUserIds.has(userId)) {
        uniqueUserIds.add(userId);
        return true;
      }
      return false;
    });

    return distinctUsers;
  }

  private loadPublicHolidays(): void {
    publicHolidays$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((publicHolidays) => {
        if (publicHolidays) {
          this.handlePublicHolidays(publicHolidays);
        }
      });
  }

getUser(userId:string|undefined|null){
  return userId? this.filteredUsers?.find(x=>x.id==userId):undefined;
}

  handleTimeRegistrations(timeRegistrations: TimeRegistration[]): void {   
    this.calenderItems=this.calenderItems.filter(x=>timeRegistrations.findIndex(t=>t.id===x.id)>-1)
    timeRegistrations?.forEach((timeRegistration) => {
      const startDate = timeRegistration.startDate
        ? DateTime.fromISO(timeRegistration.startDate.toString(), {
            zone: 'utc',
          })
        : undefined;
      const endDate = timeRegistration.endDate
        ? DateTime.fromISO(timeRegistration.endDate.toString(), { zone: 'utc' })
        : undefined;
      const activitySort = this.timeSorts?.find(
        (s) => s.id === timeRegistration.timeSortId
      );
      const calendarItem: CalendarItem = {
        id: timeRegistration.id,
        startDate,
        endDate,
        title: timeRegistration.title,
        description: this.isManager && this.userControlVal?.length > 1 ? this.getUserValue(timeRegistration.userId ?? "") : '',
        backgroundColor: CalendarColors.White,
        accentColor: activitySort?.color ?? CalendarColors.White,
        isDraggable: true,
      };

      const existingCalenderItemIndex = this.calenderItems.findIndex(
        (item) => item.id === calendarItem.id
      );

      if (existingCalenderItemIndex !== -1) {
        if (
          this.calenderItems[existingCalenderItemIndex].title !==
            calendarItem.title ||
          this.calenderItems[existingCalenderItemIndex].startDate !==
            calendarItem.startDate ||
          this.calenderItems[existingCalenderItemIndex].endDate !==
            calendarItem.endDate
        ) {
          this.calenderItems = this.calenderItems.filter(
            (item) => item.id !== calendarItem.id
          );
          this.calenderItems = [...this.calenderItems, calendarItem];
        }
      } else {
        this.calenderItems = [...this.calenderItems, calendarItem];
      }
    });
  }

  private parseDate(date: string): Date {
    const [datePart, timePart] = date.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');
    const utcDate = new Date(
      Date.UTC(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hours),
        Number(minutes)
      )
    );

    return utcDate;
  }

  private addTimeRegistration(
    originalStartDate: DateTime,
    originalEndDate: DateTime
  ): void {
    this.detailsDialog
      .open(RegistrationDetailsComponent, {
        autoFocus: false,
        disableClose: true,
        data: {
          selectedUserIds: this.userControlVal,
          isManager:this.isManager,
          users:this._userFilter(),
          startDate: originalStartDate,
          endDate: originalEndDate,
          timeRegistrations: this.timeRegistrations?.slice(),
        },
        maxWidth: '98vw',
        maxHeight: '90vh',
      })
      .afterClosed()
      .subscribe((timeRegistration) => {
        if (timeRegistration) {
          this.initTimeRegistrations(this.userControlVal)
          setTimeout(() => {
            const startDate = DateTime.fromISO(
              timeRegistration.startDate.toString(),
              { zone: 'utc' }
            );
            this.navDate = startDate; //this.parseDate(timeRegistration.startDate);
            setTimeout(() => {
              this.kalender.scrollToItem(timeRegistration?.id);
            }, 0);
          }, 0);
          this.calculateTotalHours(startDateView$.value, endDateView$.value);
        }
      });
  }

  // private getOriginalDate(date: DateTime): DateTime {
  //   const offsetDifferenceInHours = date.getTimezoneOffset() / 60;
  //   const originalDate = new Date(date);
  //   originalDate.setHours(originalDate.getHours() + offsetDifferenceInHours);
  //   return originalDate;
  // }

  // private getDateWithoutOffset(date: DateTime): DateTime {
  //   const offsetDifferenceInHours = date.getTimezoneOffset() / 60;
  //   const originalDate = new Date(date);
  //   originalDate.setHours(originalDate.getHours() - offsetDifferenceInHours);
  //   return originalDate;
  // }

  loadTimeRegistrationsAndUserSkills(userIds:string[]): void {
    const loadingMessage = this.getTranslation('loadingData');
    this.loaderService.startLoading(
      loadingMessage,
      () =>
        new Observable((observer) => {
          const filters=  userIds.map(id=>
              new Filter({
              columnName: 'UserId',
              value: id,
              operator: 'eq'
          }));
          forkJoin([
            this.timeRegistrationFacade.getTimeRegistrations$(filters,this.loadTimeInterval),
            this.timeSortFacade.getUserSkill$(userIds)
          ]
          )
            .subscribe(()=>{
              if(this.msal.userId)
                setTimeout(() => {
                  this.initTimeRegistrations([this.msal.userId])
                }, 0);
              observer.next();
              observer.complete();
            })
        })
    );
  }
  loadUserSkills(userIds:string[]){
    // const loadingMessage = this.getTranslation('loadingTimeRegistrations');
    const loadingMessage = 'User Skills';

    this.loaderService.startLoading(
      loadingMessage,
      () =>
        new Observable((observer) => {
          this.timeSortFacade.getUserSkill$(userIds)
          .pipe(takeUntil(this.destroy$))
          .subscribe(()=>{
            observer.next();
            observer.complete();
          })
        })
    );
  }

  private initTotalHours(currentView: string): void {
    const today = getTodayInUTC();
    let startDateView = getTodayInUTC();
    let endDateView = getTodayInUTC();

    if (currentView === 'day') {
      startDateView = startDateView.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      endDateView = endDateView.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });
      // startDateView.setHours(0, 0, 0, 0);
      // endDateView.setHours(23, 59, 59, 999);
    } else if (currentView === 'week') {
      startDateView = today.startOf('week');
      // new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDate() - today.getDay()
      // );
      endDateView = today.endOf('week').set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });
      // new Date(
      //   today.getFullYear(),
      //   today.getMonth(),
      //   today.getDate() - today.getDay() + 6
      // );
      //endDateView.setHours(23, 59, 59, 999);
    } else if (currentView === 'month') {
      startDateView = today.startOf('month'); //new Date(today.getFullYear(), today.getMonth(), 1);
      endDateView = today.endOf('month').set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      }); //new Date(today.getFullYear(), today.getMonth() + 1, 0);
      //endDateView.setHours(23, 59, 59, 999);
    }

    startDateView$.next(startDateView);
    endDateView$.next(endDateView);
    this.calculateTotalHours(startDateView$.value, endDateView$.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
