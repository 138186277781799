import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
  DashboardComponent,
  EntityObject,
  Filter,
  GroupUser,
  IProjectsService,
  ITasksService,
  Project,
  Task,
} from 'processdelight-angular-components';
import { Observable, catchError, map, throwError } from 'rxjs';
import { TimeSortType } from '../domain/enums/time-sort-type';
import { DummyProject } from '../domain/models/dummy-project.model';
import { Language } from '../domain/models/language.model';
import { LoadTimeInterval } from '../domain/models/load-time-interval';
import { PublicHolidayModel } from '../domain/models/public-holiday.model';
import { Skill } from '../domain/models/skill.model';
import { TaskType } from '../domain/models/task-type.model';
import { TeamCalendarItem } from '../domain/models/team-calendar-item';
import { TimeRegistration } from '../domain/models/time-registration.model';
import { TimeSort } from '../domain/models/time-sort';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { UserSkill } from '../domain/models/user-skill';
import { camelcaseKeys } from '../helper/object.functions';
import { FunctionsService } from './functions.service';

@Injectable({ providedIn: 'root' })
export class ApiService implements ITasksService, IProjectsService {
  private apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  getTasks(
    pageSize: number,
    page: number,
    sortedColumn: string,
    sortDirection: string,
    internalSort: boolean,
    internalFilterString: string,
    dataFilterString: string
  ): Observable<{
    result: EntityObject<Task>[];
    pagingCookie: string;
    totalRecordCount: number;
  }> {
    return this.httpClient
      .get<{
        result: EntityObject<Task>[];
        pagingCookie: string;
        totalRecordCount: number;
      }>(
        `${
          this.apiBase
        }/task?pageSize=${pageSize}&page=${page}&internalFilter=${internalFilterString}${this.orderByQuery(
          internalSort,
          sortedColumn,
          sortDirection
        )}&dataFilter=${dataFilterString}`
      )
      .pipe(
        map(({ result, pagingCookie, totalRecordCount }) => ({
          result: result.map(
            (c) =>
              new EntityObject<Task>({
                ...c,
                entity: new Task(c.entity ?? {}),
              })
          ),
          pagingCookie,
          totalRecordCount,
        }))
      );
  }

  orderByQuery(internalSort: boolean, column: string, direction: string) {
    if (!column || !direction) return '';
    else
      return (
        (internalSort ? '&internalOrderBy' : '&dataOrderBy') +
        `=${column}&orderByDirection=${direction.toUpperCase()}`
      );
  }

  public getTaskById(id: string): Observable<Task> {
    return this.httpClient.get<Task>(`${this.apiBase}/task/${id}`).pipe(
      map((task) => new Task(task)),
      catchError((error) => throwError(() => error))
    );
  }

  public getProjects(
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number
  ): Observable<{ result: Project[]; totalRecordCount: number }> {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/project?orderBy=${orderBy}&direction=${direction}&pageSize=${pageSize}&page=${page}`;
    if (filter !== '') url += `&filter=${filter}`;
    return this.httpClient
      .get<{ result: Project[]; totalRecordCount: number }>(url)
      .pipe(
        map((x) => ({ result: x.result, totalRecordCount: x.totalRecordCount }))
      );
  }

  public getProject(projectId: string): Observable<Project> {
    return this.httpClient
      .get<Project>(`${this.apiBase}/project/${projectId}`)
      .pipe(map((p) => new Project(camelcaseKeys(p))));
  }

  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.apiBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  public getTaskTypes(): Observable<TaskType[]> {
    const url = `${this.apiBase}/task/type`;
    return this.httpClient.get<TaskType[]>(url).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/session/keepalive`, {});
  }

  /*
  getAppInfo(app: string) {
    return this.httpClient
      .get<AppInfo>(`${this.apiBase}/organization/app/${app}`)
      .pipe(map((info) => new AppInfo(camelcaseKeys(info))));
  }
  */

  getLanguages() {
    return this.httpClient
      .get<Language[]>(`${this.apiBase}/user/languages`)
      .pipe(map((ls) => ls.map((l) => new Language(camelcaseKeys(l)))));
  }

  getTranslations(lang: string) {
    return this.httpClient.get<any>(
      `${this.apiBase}/user/translations/${lang}`
    );
  }

  getPublicHolidays(year: number) {
    return this.httpClient
      .get<PublicHolidayModel[]>(`${this.apiBase}/public-holiday/${year}`)
      .pipe(
        map((publicHolidays) =>
          publicHolidays.map((p) => new PublicHolidayModel(camelcaseKeys(p)))
        )
      );
  }
  getTimeSorts() {
    return this.httpClient
      .get<TimeSort[]>(
        `${this.apiBase}/time-sort?filter(type=${TimeSortType.Time})`
      )
      .pipe(
        map((timeSorts) => timeSorts.map((t) => new TimeSort(camelcaseKeys(t))))
      );
  }

  addTimeSort(timeSorts: TimeSort[]) {
    return this.httpClient
      .post<TimeSort[]>(`${this.apiBase}/time-sort`, timeSorts)
      .pipe(
        map((timeSorts) => timeSorts.map((s) => new TimeSort(camelcaseKeys(s))))
      );
  }

  updateTimeSort(timeSorts: TimeSort[]) {
    return this.httpClient
      .patch<TimeSort[]>(`${this.apiBase}/time-sort`, timeSorts)
      .pipe(
        map((timeSorts) => timeSorts.map((t) => new TimeSort(camelcaseKeys(t))))
      );
  }

  removeTimeSorts(timeSortIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/time-sort`, {
      body: timeSortIds,
    });
  }
  getUserSkills(filters: Filter[]) {
    let url = `${this.apiBase}/ishtartasks/user-skill`;

    if (filters.length > 0)
      url += `?filter=${this.filterQuery(filters, ' or ')}`;

    return this.httpClient
      .get<UserSkill[]>(url)
      .pipe(
        map((userSkills) =>
          userSkills.map((u) => new UserSkill(camelcaseKeys(u)))
        )
      );
  }

  getSkills() {
    return this.httpClient
      .get<Skill[]>(`${this.apiBase}/skill`)
      .pipe(map((skills) => skills.map((s) => new Skill(camelcaseKeys(s)))));
  }

  getDummyProjects() {
    return this.httpClient
      .get<DummyProject[]>(`${this.apiBase}/dummy-project`)
      .pipe(
        map((dummyProjects) =>
          dummyProjects.map((p) => new DummyProject(camelcaseKeys(p)))
        )
      );
  }

  addDummyProject(dummyProjects: DummyProject[]) {
    return this.httpClient
      .post<DummyProject[]>(`${this.apiBase}/dummy-project`, dummyProjects)
      .pipe(
        map((dummyProjects) =>
          dummyProjects.map((d) => new DummyProject(camelcaseKeys(d)))
        )
      );
  }

  updateDummyProject(dummyProjects: DummyProject[]) {
    return this.httpClient
      .patch<DummyProject[]>(`${this.apiBase}/dummy-project`, dummyProjects)
      .pipe(
        map((dummyProjects) =>
          dummyProjects.map((d) => new DummyProject(camelcaseKeys(d)))
        )
      );
  }

  removeDummyProjects(dummyProjectIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/dummy-project`, {
      body: dummyProjectIds,
    });
  }

  getIshtarOoOAbsences(filters: Filter[]) {
    let url = `${this.apiBase}/team-calendar`;

    if (filters.length > 0) {
      url += `?${this.filterOoOAbsencesQuery(filters)}`;
    }

    return this.httpClient
      .get<TeamCalendarItem[]>(url)
      .pipe(
        map((calendarItems) =>
          calendarItems.map((c) => new TeamCalendarItem(camelcaseKeys(c)))
        )
      );
  }

  getTimeRegistrations(userIds: string[], loadTimeInterval: LoadTimeInterval) {
    let url = `${this.apiBase}/time-registration`;
    const filters = userIds!.map(
      (user) =>
        new Filter({
          columnName: 'UserId',
          value: user,
          operator: 'eq',
        })
    );
    const filterQuery = this.filterQuery(filters, ' or ');
    const intervalQuery = this.getIntervalToFilter(loadTimeInterval);
    url += `?filter=${
      filters.length > 0
        ? `(${filterQuery}) ${intervalQuery ? `and (${intervalQuery})` : ''}`
        : `${intervalQuery}`
    }`;

    return this.httpClient
      .get<TimeRegistration[]>(url)
      .pipe(
        map((timeRegistrations) =>
          timeRegistrations.map((t) => new TimeRegistration(camelcaseKeys(t)))
        )
      );
  }
  addTimeRegistration(timeRegistrations: TimeRegistration[]) {
    timeRegistrations = timeRegistrations.map((tr) => {
      const newReg = new TimeRegistration(tr);
      Object.keys(newReg).forEach((key) => {
        const value = (newReg as any)[key];
        if (typeof value === 'object' && DateTime.isDateTime(value))
          (newReg as any)[key] = value.toUTC();
      });
      return newReg;
    });
    return this.httpClient
      .post<TimeRegistration[]>(
        `${this.apiBase}/time-registration`,
        timeRegistrations
      )
      .pipe(
        map((timeRegistrations) =>
          timeRegistrations.map((t) => new TimeRegistration(camelcaseKeys(t)))
        )
      );
  }

  updateTimeRegistration(timeRegistrations: TimeRegistration[]) {
    timeRegistrations = timeRegistrations.map((tr) => {
      const newReg = new TimeRegistration(tr);
      Object.keys(newReg).forEach((key) => {
        const value = (newReg as any)[key];
        if (typeof value === 'object' && DateTime.isDateTime(value))
          (newReg as any)[key] = value.toUTC();
      });
      return newReg;
    });
    return this.httpClient
      .patch<TimeRegistration[]>(
        `${this.apiBase}/time-registration`,
        timeRegistrations
      )
      .pipe(
        map((timeRegistrations) =>
          timeRegistrations.map((t) => new TimeRegistration(camelcaseKeys(t)))
        )
      );
  }

  removeTimeRegistrations(timeRegistrationIds: string[]) {
    return this.httpClient.delete<string[]>(
      `${this.apiBase}/time-registration`,
      { body: timeRegistrationIds }
    );
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/organization/groups`)
      .pipe(map((group) => group.map((g) => new GroupUser(camelcaseKeys(g)))));
  }

  private filterOoOAbsencesQuery(filters: Filter[]) {
    if (filters.length > 0) {
      return (
        'filter=' +
        filters
          .map((filter) => {
            if (filter.columnName === 'VacationType/Name') {
              return `(${filter.columnName} ne '${filter.value}')`;
            } else {
              return `('${filter.value}' in ${filter.columnName})`;
            }
          })
          .join(' and ')
      );
    } else {
      return '';
    }
  }

  private filterQuery(filters: Filter[], joinValue: string) {
    if (filters.length > 0) {
      return filters
        .map(
          (filter) =>
            `(${filter.columnName} ${filter.operator ?? 'eq'} ${filter.value})`
        )
        .join(joinValue ?? ' and ');
    } else {
      return '';
    }
  }

  private getIntervalToFilter(loadTimeInterval: LoadTimeInterval) {
    let filter = '';
    if (loadTimeInterval.start) {
      const startDate = loadTimeInterval.start.toISO({
        suppressMilliseconds: true,
        includeOffset: false,
      });
      filter = filter + `(startDate ge ${startDate})`;
    }
    if (loadTimeInterval.end) {
      const endDate = loadTimeInterval.end.toISO({
        suppressMilliseconds: true,
        includeOffset: false,
      });

      filter =
        filter + (filter.length > 0 ? ' and ' : '') + `(endDate le ${endDate})`;
    }
    return filter;
  }
}
