import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../domain/models/language.model';
import { PublicHolidayModel } from '../domain/models/public-holiday.model';
import { TimeSort } from '../domain/models/time-sort';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';

export const license$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const languages$ = new BehaviorSubject<Language[]>([]);
export const translations$ = new BehaviorSubject<any>({});
export const publicHolidays$ = new BehaviorSubject<
  PublicHolidayModel[] | undefined
>(undefined);
export const calendarView$ = new BehaviorSubject<
  { startDate: DateTime; endDate: DateTime } | undefined
>(undefined);
export const timeSorts$ = new BehaviorSubject<TimeSort[] | undefined>(
  undefined
);
