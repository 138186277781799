import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { timeSortState } from './time-sort.reducer';
import { Subject, map } from 'rxjs';
import {
  addTimeSort,
  getTimeSorts,
  getUserSkills,
  removeTimeSorts,
  updateTimeSort,
} from './time-sort.actions';
import { TimeSort } from '../../domain/models/time-sort';
import { UserSkill } from '../../domain/models/user-skill';
import { Filter } from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class TimeSortFacade {
  timeSorts$ = this._store.pipe(
    select(timeSortState),
    map((state) => state.timeSorts)
  );

  pagingCookie$ = this._store.pipe(
    select(timeSortState),
    map((state) => state.pagingCookie)
  );

  totalRecordCount$ = this._store.pipe(
    select(timeSortState),
    map((state) => state.totalRecordCount)
  );

  userSkills$ =  this._store.pipe(
    select(timeSortState),
    map((state) => state.userSkills)
  );
  allSelectedCommonUsersSkills$ = (userIds: string[]) => this._store.pipe(
    select(timeSortState),
    map((state) => {
      const initialUserSkills = state.userSkills.filter(us => us.userId === userIds[0]);

      const commonUserSkills = userIds.slice(1).reduce((common, userId) => {
        const userSkillsForId = state.userSkills.filter(us => us.userId === userId);

        return common.filter(us =>
          userSkillsForId.some(x => x.skillId === us.skillId)
        );
      }, initialUserSkills);

      return commonUserSkills;
    })
  );

  constructor(private _store: Store<AppState>) {}

  getUserSkill$(userIds:string[]) {
    const subject = new Subject<void>();
    const filters=userIds.map(id=>new Filter({
      columnName: 'UserId',
              value: id,
              operator: 'eq'
    }))
      this._store.dispatch(
        getUserSkills({
          filters,
          callback: () => {
            subject.next();
            subject.complete();
          },
        })
      );
    return subject.asObservable();
  }

  getTimeSorts$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getTimeSorts({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  addTimeSort(timeSorts: TimeSort[]) {
    this._store.dispatch(addTimeSort({ timeSorts }));
  }

  updateTimeSort(timeSorts: TimeSort[]) {
    this._store.dispatch(updateTimeSort({ timeSorts }));
  }

  removeTimeSorts(ishtarTimeTimesortIds: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeTimeSorts({
        ishtarTimeTimesortIds,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }
}
