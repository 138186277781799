import { BehaviorSubject } from 'rxjs';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { GroupUser } from 'processdelight-angular-components';
import { Language } from '../domain/models/language.model';
import { PublicHolidayModel } from '../domain/models/public-holiday.model';
import { UserSkill } from '../domain/models/user-skill';
import { TimeSort } from '../domain/models/time-sort';
import { Project } from '../domain/models/project';
import { Task } from '../domain/models/task';
import { DateTime } from 'luxon';

export const license$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const languages$ = new BehaviorSubject<Language[]>([]);
export const translations$ = new BehaviorSubject<any>({});
export const publicHolidays$ = new BehaviorSubject<
  PublicHolidayModel[] | undefined
>(undefined);
export const appName$ = new BehaviorSubject<string>('');
export const startDateView$ = new BehaviorSubject<DateTime | undefined>(
  undefined
);
export const endDateView$ = new BehaviorSubject<DateTime | undefined>(
  undefined
);
export const timeSorts$ = new BehaviorSubject<TimeSort[] | undefined>(
  undefined
);
export const projects$ = new BehaviorSubject<Project[] | undefined>(
  undefined
);
export const tasks$ = new BehaviorSubject<Task[] | undefined>(undefined);
