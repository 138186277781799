import { Action, createReducer, on } from '@ngrx/store';
import { AADUser, GroupUser } from 'processdelight-angular-components';
import { AppState } from 'src/app/app.reducer';
import { getGroupUsersResolved } from './group.actions';

export const featureSlice = 'group';

export interface State {
  groups: GroupUser[];
  users: AADUser[];
}

const defaultState: State = {
  groups: [],
  users: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return groupReducer(state, action);
}

export const initialState: State = defaultState;
export const groupReducer = createReducer(
  initialState,
  on(getGroupUsersResolved, (state, { groupUsers }) => ({
    ...state,
    groups: [...groupUsers],
    users: [
      ...[
        ...groupUsers.flatMap((g) => g.group?.members ?? []),
        ...groupUsers.flatMap((g) => g.group?.owners ?? []),
      ]
        .filter((u) => !!u)
        .filter(
          (value, index, self) =>
            self.findIndex((u) => u.id === value.id) === index
        ),
    ],
  }))
);

export const groupState = (state: AppState) => state.coreFeature.group;
