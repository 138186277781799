import {
  BaseEntity,
  Entity,
  GroupUser,
} from 'processdelight-angular-components';
import { DateTime } from 'luxon';
import { DummyProject } from './dummy-project.model';

export class TimeRegistration extends Entity {
  title?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  user?: GroupUser;
  projectId?: string | null = null;
  dummyProject?: DummyProject | null = null;
  timeSortId!: string;
  taskId?: string | null = null;
  typeId?: string | null = null;
  skillId?: string | null = null;
  newTaskWanted?: boolean = false;
  userId?: string | null = null;
  resourceUsers: GroupUser[] | undefined = [];
  constructor(obj: Partial<TimeRegistration>) {
    super(obj);
    Object.assign(this, obj);

    if (typeof obj.startDate == 'string')
      this.startDate = DateTime.fromISO(obj.startDate, {
        zone: 'utc',
      }).toLocal();
    if (typeof obj.endDate == 'string')
      this.endDate = DateTime.fromISO(obj.endDate, {
        zone: 'utc',
      }).toLocal();

    if (obj.startDate instanceof DateTime)
      this.startDate = DateTime.fromMillis(obj.startDate.valueOf(), {
        zone: 'utc',
      }).toLocal();
    if (obj.endDate instanceof DateTime)
      this.endDate = DateTime.fromMillis(obj.endDate.valueOf(), {
        zone: 'utc',
      }).toLocal();
  }
}
