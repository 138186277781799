import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, first, map } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { getGroupUsers } from './group.actions';
import {
  AADUser,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { groupState } from './group.reducer';

@Injectable({
  providedIn: 'root',
})
export class GroupFacade {
  groups$ = this._store.pipe(
    select(groupState),
    map((state) => state.groups)
  );

  isTimeManager$ = this._store.pipe(
    select(groupState),
    map((state) => state.groups),
    map((groups) =>
      groups.some((g) =>
        g.group?.owners?.find((o) => o?.id === this.msal.userId)
      )
    )
  );

  myUsers$ = this._store.pipe(
    select(groupState),
    map((state) => state.groups),
    map((groups) =>
      groups
        .filter((g) => g.group?.owners?.find((o) => o?.id === this.msal.userId))
        .flatMap((g) => g.group?.members ?? [])
    ),
    map((users) => users.filter((u) => !!u).map((u) => u.id)),
    map((users) => [...new Set([...users, this.msal.userId])])
  );

  users$ = this._store.pipe(
    select(groupState),
    map((state) => state.users),
    map((users) =>
      users.length === 0
        ? [
            new AADUser({
              id: this.msal.userId,
              displayName: this.msal.displayName,
            }),
          ]
        : users
    )
  );

  get groups() {
    let groups;
    this.groups$.pipe(first()).subscribe((data) => (groups = data));
    return groups;
  }

  get isTimeManager(): boolean {
    let isTimeManager: any;
    this.isTimeManager$
      .pipe(first())
      .subscribe((data) => (isTimeManager = data));
    return isTimeManager;
  }

  get myUsers(): string[] {
    let myUsers: any;
    this.myUsers$.pipe(first()).subscribe((data) => (myUsers = data));
    return myUsers;
  }

  get users(): AADUser[] {
    let users: any;
    this.users$.pipe(first()).subscribe((data) => (users = data));
    return users;
  }

  constructor(
    private _store: Store<AppState>,
    private msal: MicrosoftAuthenticationService
  ) {}

  getGroups$() {
    const subject = new Subject<void>();
    this._store.dispatch(
      getGroupUsers({
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
