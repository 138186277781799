import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { inject, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import {
  APP_STARTUP,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_FUNCTIONS_SCOPE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_INSTANCE,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_URL,
  MsalModule,
  MyDateAdapterOptions,
  microsoftAuthenticationInterceptor,
  SessionExpiredModule,
  LOCALE_INJECTOR,
  TranslationService,
  MY_FORMATS,
  TASKS_SERVICE_TOKEN,
  PROJECTS_SERVICE_TOKEN,
} from 'processdelight-angular-components';
import { StoreModule, provideStore } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from './environments/environment';
import { StartUpService } from './app/core/services/startup.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from '@angular/material-luxon-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { ApiService } from './app/core/services/api.service';

bootstrapApplication(AppComponent, {
  providers: [
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    { provide: ISHTAR365_LAST_ROUTE_KEY, useValue: environment.lastRouteKey },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['home'] },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 1500,
        position: 'below',
        disableTooltipInteractivity: true,
      },
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        firstDayOfWeek: 1,
      },
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: TASKS_SERVICE_TOKEN, useClass: ApiService },
    { provide: PROJECTS_SERVICE_TOKEN, useClass: ApiService },

    {
      provide: MAT_DATE_LOCALE,
      useFactory: LOCALE_INJECTOR,
      deps: [TranslationService],
    },
    provideRouter(routes),
    provideStore({}),
    provideEffects([]),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    provideAnimations(),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule,
      SessionExpiredModule
    ),
  ],
});
