import { CommonModule } from '@angular/common';
import {
  Component,
  AfterViewInit,
  Input,
  ViewChild,
  inject,
  SimpleChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  ControlContainer,
  FormControl,
  NgControl,
  ControlValueAccessor,
} from '@angular/forms';
import {
  PreloadSelectorComponent,
  ITasksService,
  TASKS_SERVICE_TOKEN,
  ISelectOption,
  DataSourceService,
  TranslationService,
} from 'processdelight-angular-components';
import { Observable, Subject, map } from 'rxjs';

@Component({
  selector: 'app-tasks-preload-selector',
  templateUrl: './tasks-preload-selector.component.html',
  styleUrls: ['./tasks-preload-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PreloadSelectorComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class TasksPreloadSelectorComponent
  implements AfterViewInit, ControlValueAccessor, OnDestroy
{
  @Input() label: string | undefined;
  @Input() isMultiple = true;

  _projectidFilter?: string;
  @Input()
  get projectidFilter(): string | undefined {
    return this._projectidFilter;
  }
  set projectidFilter(v: string | undefined) {
    this._projectidFilter = v;
    if (!this.preloadSelectorCmp) return;
    this.preloadSelectorCmp.filterTriggered.next('');
  }

  @ViewChild(PreloadSelectorComponent) preloadSelectorCmp:
    | PreloadSelectorComponent
    | undefined;

  private readonly tasksService: ITasksService = inject(TASKS_SERVICE_TOKEN);
  protected readonly fetchTaskEntityHandler = this.fetchTaskEntity.bind(this);
  protected readonly fetchTasksEntitiesHandler =
    this.fetchTasksEntities.bind(this);

  ngAfterViewInit(): void {
    if (!this.preloadSelectorCmp)
      throw new Error('PreloadSelectorComponent not found');
  }

  private fetchTasksEntities(searchBy?: string): Observable<ISelectOption[]> {
    const pageNumber = 0;
    const sortedColumn = 'Number';
    const sortDirection = 'asc';
    const internalSort = true;
    const projectidFilter =
      this._projectidFilter != null
        ? 'projectId eq ' + this._projectidFilter
        : 'projectId is null';
    const internalFilterString = searchBy
      ? `'${searchBy}' in Title and ${projectidFilter}`
      : projectidFilter;
    const dataFilterString = '';

    if (!this.preloadSelectorCmp)
      throw new Error('PreloadSelectorComponent not found');

    return this.tasksService
      .getTasks(
        this.preloadSelectorCmp.ScrollPageSize,
        pageNumber,
        sortedColumn,
        sortDirection,
        internalSort,
        internalFilterString,
        dataFilterString
      )
      .pipe(
        map((entities) =>
          entities.result.map(
            (item) =>
              ({
                id: item.entity?.id,
                value: item.entity?.title,
                label: item.entity?.number + ' ' + item.entity?.title,
              } as ISelectOption)
          )
        )
      );
  }

  private fetchTaskEntity(id: string): Observable<ISelectOption> {
    return this.tasksService.getTaskById(id).pipe(
      map(
        (task) =>
          ({
            id: task.id,
            value: task.title,
            label: task.number + ' ' + task.title,
          } as ISelectOption)
      )
    );
  }

  @Input() formControl: FormControl<any> | undefined;
  @Input() formControlName: string | undefined;

  protected readonly ngControl = inject(NgControl, {
    optional: true,
    self: true,
  });
  protected readonly controlContainer = inject(ControlContainer, {
    optional: true,
  });
  protected readonly destroy$ = new Subject<void>();

  protected onChange: (_: any) => void = () => void 0;
  protected onTouched: () => void = () => void 0;

  public get control(): FormControl<any> {
    return (
      this.formControl ||
      (this.formControlName &&
        (this.controlContainer?.control?.get(
          this.formControlName
        ) as FormControl<any>)) ||
      (this.ngControl?.control as FormControl<any>)
    );
  }

  constructor() {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  public writeValue(value: any): void {
    if (!value) return;
    if (this.control?.value) return;
    this.control?.setValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (!this.control) return;
    if (isDisabled === this.control.disabled) return;

    isDisabled
      ? this.control?.disable({ emitEvent: false })
      : this.control?.enable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
