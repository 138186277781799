import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { TimeRegistration } from '../../domain/models/time-registration.model';
import {
  addTimeRegistrationResolved,
  getTimeRegistrationsResolved,
  removeTimeRegistrationsResolved,
  updateTimeRegistrationFailed,
  updateTimeRegistrationResolved,
} from './time-registration.actions';

export const featureSlice = 'timeRegistration';

export interface State {
  timeRegistrations:TimeRegistration[] ;
  ishtarTimeRegistrationIds?: string[];
}

const defaultState: State = {
  timeRegistrations: [],
  ishtarTimeRegistrationIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return timeRegistrationReducer(state, action);
}

export const initialState: State = defaultState;

export const timeRegistrationReducer = createReducer(
  initialState,
  on(getTimeRegistrationsResolved, (state, {  result }) => ({
    ...state,
    timeRegistrations: result
  })),
  on(
    removeTimeRegistrationsResolved,
    (state, { ishtarTimeRegistrationIds }) => {
      return {
        ...state,
        timeRegistrations: state.timeRegistrations.filter((t) => !ishtarTimeRegistrationIds.includes(t.id!)),
      };
    }
  ),
  on(updateTimeRegistrationResolved, (state, { updatedTimeRegistrations }) => {
      return {
      ...state,
      timeRegistrations: state.timeRegistrations.map((t) => updatedTimeRegistrations.find((s) => t.id === s.id) ?? t),
    };
  }),
  on(updateTimeRegistrationFailed, (state, { error }) => ({
    ...state,
    error,
  })),
  on(addTimeRegistrationResolved, (state, { addedTimeRegistrations }) => {

    return {
      ...state,
      timeRegistrations: addedTimeRegistrations.concat([...(state.timeRegistrations?? [])]),
    };
  })
);

export const timeRegistrationState = (state: AppState) =>
  state.coreFeature.timeRegistration;
