import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import {
  CalendarComponent,
  CalendarItem,
  ContextMenuAction,
  Filter,
  LoaderService,
  MicrosoftAuthenticationService,
  getTodayInUTC,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  first,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { DeletePopupComponent } from '../configuration/shared/delete-popup/delete-popup.component';
import {
  calendarView$,
  license$,
  publicHolidays$,
  translations$,
} from '../core/data/data.observables';
import { LoadTimeInterval } from '../core/domain/models/load-time-interval';
import { PublicHolidayModel } from '../core/domain/models/public-holiday.model';
import { TeamCalendarItem } from '../core/domain/models/team-calendar-item';
import { TimeRegistration } from '../core/domain/models/time-registration.model';
import { TimeSort } from '../core/domain/models/time-sort';
import { CalendarItemFacade } from '../core/store/calendar-item/calendar-item.facade';
import { GroupFacade } from '../core/store/group/group.facade';
import { LicenseFacade } from '../core/store/license.facade';
import { TimeRegistrationFacade } from '../core/store/time-registration/time-registration.facade';
import { TimeSortFacade } from '../core/store/time-sort/time-sort.facade';
import { CalendarColors } from '../core/utils/constants';
import { DateUtilsService } from '../core/utils/date-utils.service';
import {
  RegistrationDetailData,
  RegistrationDetailsComponent,
} from './registration-details/registration-details.component';

@Component({
  standalone: true,
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  imports: [
    CommonModule,
    CalendarComponent,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
  ],
})
export class CalendarIshtarTimeComponent implements OnInit, OnDestroy {
  translations = translations$.value;
  destroy$ = new Subject<void>();

  userControl = new FormControl([] as string[]);

  license = license$.value;

  timeRegistrationsFilters: Filter[] = [];
  absencesFilters: Filter[] = [];

  timeRegistrations: TimeRegistration[] = [];
  calendarItemsOoO: TeamCalendarItem[] = [];

  timeSorts: TimeSort[] = [];

  calendarHeight = 1240;
  hourBlocks = 2;
  totalAmountOfHours = '';

  navDate = DateTime.now().toUTC();
  currentView = 'week';

  isManager = this.groupFacade.isTimeManager;
  myUsers = this.groupFacade.myUsers;
  users = this.groupFacade.users;
  isFirstLoading = true;

  get userControlVal(): string[] {
    return this.userControl.value as string[];
  }

  @ViewChild('userFilter')
  userDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('kalender') kalender!: CalendarComponent;

  @Input() calenderItems: CalendarItem[] = [];
  @Input() hideTopBar = false;
  @Input() allowItemCollision = true;

  @Output() monthChanged = new EventEmitter<{
    startDate: DateTime;
    endDate: DateTime;
  }>();
  @Output() addCalenderItem = new EventEmitter<{
    startDate: DateTime;
    endDate: DateTime;
  }>();
  @Output() editCalenderItem = new EventEmitter<CalendarItem>();

  constructor(
    private readonly licenseFacade: LicenseFacade,
    private readonly calendarItemOoOFacade: CalendarItemFacade,
    private readonly loaderService: LoaderService,
    private readonly timeRegistrationFacade: TimeRegistrationFacade,
    private readonly timeSortFacade: TimeSortFacade,
    private readonly groupFacade: GroupFacade,
    private readonly dateUtils: DateUtilsService,
    private msal: MicrosoftAuthenticationService,
    private detailsDialog: MatDialog,
    private deleteDialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dateChanged({
      startDate: DateTime.now().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      endDate: DateTime.now().set({
        hour: 23,
        minute: 59,
        second: 0,
        millisecond: 0,
      }),
    });

    this.loadPublicHolidays();
    this.loadIshtarSorts();

    calendarView$.pipe(takeUntil(this.destroy$)).subscribe((view) => {
      this.calculateTotalHours(view?.startDate, view?.endDate);
    });

    if (this.msal.userId) {
      this.userControl.patchValue([this.msal.userId]);
      this.timeRegistrationsFilters = [];
      this.absencesFilters = [];
      this.calenderItems = this.calenderItems.filter(
        (c) => !this.calendarItemsOoO.some((OoO) => OoO.id === c.id)
      );
      this.calenderItems = this.calenderItems.filter(
        (c) => !this.timeRegistrations.some((t) => t.id === c.id)
      );

      this.loadAbsences(this.msal.userId);
    }

    this.userControl.valueChanges.subscribe((newValue) => {
      if (newValue) this.initTimeRegistrations(newValue);
    });
  }

  private initTimeRegistrations(userIds: string[]) {
    this.timeRegistrations = [];
    this.timeRegistrationFacade.timeRegistrations$
      .pipe(
        map((timeRegistrations) =>
          timeRegistrations.filter((x) => userIds.includes(x.userId!))
        ),
        first()
      )
      .subscribe((timeRegistrations) => {
        this.timeRegistrations = timeRegistrations;
        this.calenderItems = timeRegistrations.map((timeRegistration) => {
          const activitySort = this.timeSorts?.find(
            (s) =>
              s.id === timeRegistration.timeSortId ||
              s.skills?.some((sk) => sk.skillId === timeRegistration.skillId)
          );
          const calendarItem: CalendarItem = {
            id: timeRegistration.id,
            startDate: timeRegistration.startDate,
            endDate: timeRegistration.endDate,
            title: timeRegistration.title,
            description:
              this.isManager && this.userControlVal?.length > 1
                ? this.getUser(timeRegistration.userId ?? '')?.displayName
                : '',
            backgroundColor: CalendarColors.White,
            accentColor: activitySort?.color ?? CalendarColors.White,
            isDraggable: true,
          };
          return calendarItem;
        });
        if (this.timeRegistrations && this.isFirstLoading) {
          this.initTotalHours(this.currentView);
          this.isFirstLoading = false;
        } else
          setTimeout(() => {
            this.recalculateTotalHours();
          }, 0);
      });
  }

  addItem(event: { startDate: DateTime; endDate: DateTime; fullDay: boolean }) {
    const startDate = event.startDate.setZone('local', { keepLocalTime: true });
    const endDate = event.endDate.setZone('local', { keepLocalTime: true });

    this.addTimeRegistration(startDate, endDate);
  }

  contextBlockMenuActionsFn = (item: DateTime) => {
    const startDate = item.setZone('local', { keepLocalTime: true });
    return [
      new ContextMenuAction({
        label: this.getTranslation('add'),
        icon: 'add',
        action: () => this.addTimeRegistration(startDate, startDate),
      }),
    ];
  };

  contextItemMenuActionsFn = (item: CalendarItem) => {
    const timeRegistrationItem = this.timeRegistrations.find(
      (t) => t.id === item.id && t.title === item.title
    );
    if (timeRegistrationItem && timeRegistrationItem.title) {
      const titleSnippet =
        timeRegistrationItem.title.length > 50
          ? timeRegistrationItem.title.substring(0, 50) + '...'
          : timeRegistrationItem.title;

      const itemToDelete = {
        ...timeRegistrationItem,
        title:
          timeRegistrationItem.startDate?.toFormat('HH:mm') +
          ' ' +
          titleSnippet,
      };

      const startDate = item.startDate!.toLocal();
      const endDate = item.endDate!.toLocal();

      return [
        new ContextMenuAction({
          label: this.getTranslation('edit'),
          icon: 'edit',
          action: () =>
            this.editTimeRegistration(timeRegistrationItem, {
              startDate,
              endDate,
            }),
        }),
        new ContextMenuAction({
          label: this.getTranslation('copy'),
          icon: 'copy',
          action: () =>
            this.editTimeRegistration(timeRegistrationItem, undefined, true),
        }),
        new ContextMenuAction({
          label: this.getTranslation('delete'),
          icon: 'delete',
          action: () =>
            this.deleteDialog
              .open(DeletePopupComponent, {
                autoFocus: false,
                data: {
                  timeRegistration: itemToDelete,
                },
                disableClose: true,
              })
              .afterClosed()
              .subscribe((result) => {
                if (result == 'submit') {
                  if (timeRegistrationItem?.id) {
                    this.timeRegistrationFacade
                      .removeTimeRegistration([timeRegistrationItem.id])
                      .pipe(first())
                      .subscribe(() => {
                        this.initTimeRegistrations(this.userControlVal);
                        this._snackBar
                          .open(
                            this.getTranslation('timeRegistrationDeleted'),
                            'X',
                            {
                              panelClass: 'app-notification-success',
                            }
                          )
                          ._dismissAfter(3000);
                      });
                  }
                }
              }),
        }),
      ];
    }
    return [];
  };

  private addTimeRegistration(
    originalStartDate: DateTime,
    originalEndDate: DateTime
  ): void {
    this.detailsDialog
      .open(RegistrationDetailsComponent, {
        autoFocus: false,
        disableClose: true,
        data: new RegistrationDetailData({
          selectedUserIds: this.userControlVal,
          isManager: this.isManager,
          users: this.myUsers,
          startDate: originalStartDate,
          endDate: originalEndDate,
          timeRegistrations: this.timeRegistrations?.slice(),
        }),
        maxWidth: '98vw',
        maxHeight: '90vh',
      })
      .afterClosed()
      .subscribe((timeRegistration: TimeRegistration[]) => {
        if (timeRegistration && timeRegistration.length) {
          this.initTimeRegistrations(this.userControlVal);
          setTimeout(() => {
            this.navDate =
              timeRegistration[0]?.startDate?.toUTC() ?? this.navDate;
            setTimeout(() => {
              this.kalender.scrollToItem(timeRegistration[0]?.id);
            }, 0);
          }, 0);
          this._snackBar
            .open(this.getTranslation('timeRegistrationAdded'), 'X', {
              panelClass: 'app-notification-success',
            })
            ._dismissAfter(3000);
        }
      });
  }

  private editTimeRegistration(
    timeRegistrationItem: TimeRegistration,
    endStart:
      | { startDate: DateTime; endDate: DateTime }
      | undefined = undefined,
    copyMode = false
  ) {
    this.detailsDialog
      .open(RegistrationDetailsComponent, {
        autoFocus: false,
        disableClose: true,
        data: new RegistrationDetailData({
          selectedUserIds: this.userControlVal,
          timeRegistrationItem,
          copyMode,
          startDate: endStart?.startDate ?? timeRegistrationItem.startDate,
          endDate: endStart?.endDate ?? timeRegistrationItem.endDate,
          timeRegistrations: this.timeRegistrations.slice(),
        }),
        maxWidth: '98vw',
        maxHeight: '90vh',
      })
      .afterClosed()
      .subscribe((timeRegistration) => {
        if (timeRegistration?.length > 0) {
          this.navDate =
            timeRegistration[0]?.startDate?.toUTC() ?? this.navDate;
          this.initTimeRegistrations(this.userControlVal);
          setTimeout(() => {
            this.kalender.scrollToItem(timeRegistration[0]?.id);
          }, 0);
        }
      });
  }

  getUser(userId: string) {
    return this.users.find((u) => userId === u?.id?.toString());
  }

  onViewChanged(event: {
    view: string;
    startDate: DateTime;
    endDate: DateTime;
  }) {
    calendarView$.next(event);
  }

  dateChanged(event: { startDate: DateTime; endDate: DateTime }) {
    if (
      !!this.timeRegistrationFacade.loadTimeInterval &&
      this.timeRegistrationFacade.loadTimeInterval!.start < event.startDate &&
      this.timeRegistrationFacade.loadTimeInterval!.end > event.endDate
    ) {
      calendarView$.next(event);
      if (this.timeRegistrations.length === 0)
        // this is needed when switching from settings to calendar, items are in state but not loaded
        this.initTimeRegistrations(this.userControlVal);
      return;
    }
    const loadingMessage = this.getTranslation('loadingData');
    const loadTimeInterval = new LoadTimeInterval();
    loadTimeInterval.end = event.endDate.plus({ months: 2 });
    loadTimeInterval.start = event.startDate.minus({ months: 2 });

    this.loaderService.startLoading(
      loadingMessage,
      () =>
        new Observable((observer) => {
          this.timeRegistrationFacade
            .getTimeRegistrations$(this.groupFacade.myUsers, loadTimeInterval)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              setTimeout(() => {
                this.initTimeRegistrations(this.userControlVal);
              }, 0);
              observer.next();
              observer.complete();
            });
        })
    );
  }

  recalculateTotalHours() {
    this.calculateTotalHours(
      calendarView$.value?.startDate,
      calendarView$.value?.endDate
    );
  }

  calculateTotalHours(
    startDate: DateTime | undefined,
    endDate: DateTime | undefined
  ): void {
    let totalMinutes = 0;
    const startView = startDate?.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const endView = endDate?.set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    //startDate?.setHours(0, 0, 0, 0);
    //endDate?.setHours(23, 59, 59, 999);

    this.timeRegistrations?.forEach((registration) => {
      const startDate = registration.startDate;
      const endDate = registration.endDate;

      if (
        startDate &&
        startView &&
        startDate >= startView &&
        endDate &&
        endView &&
        endDate <= endView
      ) {
        const durationMs = endDate.valueOf() - startDate.valueOf();
        const durationMinutes = durationMs / 60000;
        totalMinutes += durationMinutes;
      }
    });

    const totalHours = totalMinutes / 60;
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    this.totalAmountOfHours = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  itemChangedByDragOrResize(event: CalendarItem) {
    const timeRegistrationDragged = this.timeRegistrations.find(
      (t) => t.id === event.id
    );
    if (timeRegistrationDragged) {
      const timeRegistrationToUpdate = new TimeRegistration({
        id: timeRegistrationDragged?.id,
        title: timeRegistrationDragged?.title,
        startDate: event.startDate?.setZone('local', { keepLocalTime: true }),
        endDate: event.endDate?.setZone('local', { keepLocalTime: true }),
        user: timeRegistrationDragged?.user,
        projectId: timeRegistrationDragged?.projectId,
        dummyProject: timeRegistrationDragged?.dummyProject,
        timeSortId: timeRegistrationDragged?.timeSortId,
        taskId: timeRegistrationDragged?.taskId,
      });

      this.editTimeRegistration(timeRegistrationToUpdate);
    }
  }

  getTranslation(label: string | undefined) {
    if (label) {
      return translations$.value[label] ?? label;
    }
  }

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  onUsersDropdownClick() {
    setTimeout(() => {
      this.userDropdown?.nativeElement.focus();
    }, 0);
  }

  handlePublicHolidays(publicHolidays: PublicHolidayModel[]): void {
    publicHolidays.forEach((publicHoliday) => {
      if (publicHoliday.holidayDate) {
        const holidayDate = DateTime.fromISO(
          publicHoliday.holidayDate.toString(),
          { zone: 'utc' }
        );
        const startDateTime = holidayDate.set({
          hour: 9,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const endDateTime = holidayDate.set({
          hour: 17,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        // const endDateTime = new Date(startDateTime);
        // endDateTime.setTime(startDateTime.valueOf() + (endTime - startTime));

        const calendarItem: CalendarItem = {
          id: publicHoliday.holidayType?.concat(holidayDate.toISO()!),
          startDate: startDateTime, //this.getDateWithoutOffset(startDateTime),
          endDate: endDateTime, //this.getDateWithoutOffset(endDateTime),
          title: publicHoliday.holidayType,
          accentColor: CalendarColors.Orange,
          backgroundColor: CalendarColors.Orange,
          isDraggable: false,
          contextMenuDisabled: true,
        };

        const itemIsOnCalendar = this.calenderItems.find(
          (item) =>
            item.id === calendarItem.id && item.title === calendarItem.title
        );

        if (!itemIsOnCalendar) {
          this.calenderItems = [...this.calenderItems, calendarItem];
        }
      }
    });
  }

  handleCalendarItemsOoO(calendarItems: TeamCalendarItem[]): void {
    calendarItems.forEach((ooOAbsence) => {
      const startDate = ooOAbsence.startDate
        ? DateTime.fromISO(ooOAbsence.startDate.toString(), { zone: 'utc' })
        : undefined;
      const endDate = ooOAbsence.endDate
        ? DateTime.fromISO(ooOAbsence.endDate.toString(), { zone: 'utc' })
        : undefined;

      const calendarItem: CalendarItem = {
        id: ooOAbsence.id,
        startDate,
        endDate,
        title:
          ooOAbsence.vacationReason +
          (ooOAbsence.vacationType?.title
            ? ' - ' + ooOAbsence.vacationType?.title
            : ''),
        backgroundColor: CalendarColors.LightGray,
        accentColor: CalendarColors.LightGray,
        isDraggable: false,
        contextMenuDisabled: true,
      };

      const itemIsOnCalendar = this.calenderItems.find(
        (item) =>
          item.id === calendarItem.id && item.title === calendarItem.title
      );

      if (!itemIsOnCalendar) {
        this.calenderItems = [...this.calenderItems, calendarItem];
      }
    });
  }

  loadAbsences(selectedPersonId: string): void {
    this.licenseFacade.hasIshtarOoOLicense$
      .pipe(takeUntil(this.destroy$))
      .subscribe((hasOoOLicense) => {
        if (hasOoOLicense) {
          const statusFilterItem = new Filter({
            columnName: 'ApprovalStatus/Status',
            value: this.getTranslation('approved'),
          });
          const userFilterItem = new Filter({
            columnName: 'Users',
            value: selectedPersonId,
          });
          const vacantionTypeFilterItem = new Filter({
            columnName: 'VacationType/Name',
            value: this.getTranslation('workFromHome'),
          });

          this.absencesFilters.push(statusFilterItem);
          this.absencesFilters.push(userFilterItem);
          this.absencesFilters.push(vacantionTypeFilterItem);

          this.loadIshtarOoOAbsences(selectedPersonId);
        }
      });
  }

  private loadIshtarSorts() {
    this.timeSortFacade.timeSorts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((timeSorts) => {
        if (timeSorts) {
          this.timeSorts = timeSorts;
        }
      });
  }

  loadIshtarOoOAbsences(selectedPersonId: string): void {
    const loadingMessage = this.getTranslation('loadingOoOAbsences');
    this.loaderService.startLoading(
      loadingMessage,
      () =>
        new Observable((observer) => {
          this.calendarItemsOoO = [];
          this.calendarItemOoOFacade
            .calendarItems$(selectedPersonId)
            .pipe(
              takeUntil(this.destroy$),
              switchMap((calendarItems) =>
                calendarItems?.length
                  ? (this.calendarItemsOoO = calendarItems)
                  : this.calendarItemOoOFacade.getCalendarItems$(
                      selectedPersonId,
                      this.absencesFilters
                    )
              ),
              take(2)
            )
            .subscribe(() => {
              this.handleCalendarItemsOoO(this.calendarItemsOoO);
              observer.next();
              observer.complete();
            });
        })
    );
  }

  private loadPublicHolidays(): void {
    publicHolidays$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((publicHolidays) => {
        if (publicHolidays) {
          this.handlePublicHolidays(publicHolidays);
        }
      });
  }

  private initTotalHours(currentView: string): void {
    const today = getTodayInUTC();
    let startDateView = getTodayInUTC();
    let endDateView = getTodayInUTC();

    if (currentView === 'day') {
      startDateView = startDateView.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      endDateView = endDateView.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });
    } else if (currentView === 'week') {
      startDateView = today.startOf('week');
      endDateView = today.endOf('week').set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });
    } else if (currentView === 'month') {
      startDateView = today.startOf('month');
      endDateView = today.endOf('month').set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });
    }

    calendarView$.next({ startDate: startDateView, endDate: endDateView });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
