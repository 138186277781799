import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, first, map } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { LoadTimeInterval } from '../../domain/models/load-time-interval';
import { TimeRegistration } from '../../domain/models/time-registration.model';
import {
  addTimeRegistration,
  getTimeRegistrations,
  removeTimeRegistrations,
  updateTimeRegistration,
} from './time-registration.actions';
import { timeRegistrationState } from './time-registration.reducer';

@Injectable({
  providedIn: 'root',
})
export class TimeRegistrationFacade {
  timeRegistrations$ = this._store.pipe(
    select(timeRegistrationState),
    map((state) => state.timeRegistrations)
  );

  loadTimeInterval$ = this._store.pipe(
    select(timeRegistrationState),
    map((state) => state.loadTimeInterval)
  );

  get loadTimeInterval(): LoadTimeInterval {
    let loadTimeInterval: any;
    this.loadTimeInterval$
      .pipe(first())
      .subscribe((data) => (loadTimeInterval = data));
    return loadTimeInterval;
  }

  constructor(private _store: Store<AppState>) {}

  getTimeRegistrations$(userIds: string[], loadTimeInterval: LoadTimeInterval) {
    const subject = new Subject<void>();
    this._store.dispatch(
      getTimeRegistrations({
        userIds,
        loadTimeInterval,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }
  addTimeRegistration$(
    timeRegistrations: TimeRegistration[]
  ): Observable<TimeRegistration[]> {
    const subject = new Subject<TimeRegistration[]>();

    this._store.dispatch(
      addTimeRegistration({
        timeRegistrations,
        callback: (timeRegistrations) => {
          subject.next(timeRegistrations);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  updateTimeRegistration$(
    timeRegistrations: TimeRegistration[]
  ): Observable<TimeRegistration[]> {
    const subject = new Subject<TimeRegistration[]>();
    this._store.dispatch(
      updateTimeRegistration({
        timeRegistrations,
        callback: (timeRegistrations) => {
          subject.next(timeRegistrations);
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }

  removeTimeRegistration(ishtarTimeRegistrationIds: string[]) {
    const subject = new Subject<void>();
    this._store.dispatch(
      removeTimeRegistrations({
        ishtarTimeRegistrationIds,
        callback: () => {
          subject.next();
          subject.complete();
        },
      })
    );
    return subject.asObservable();
  }
}
