<div class="wrapper d-flex flex-column h-100">
  <div class="row border-bottom">
    <div  class="col-md-6">
      <form *ngIf="isManager" class="topRowContainer sticky-top flex-shrink-0 user-form" >
         <mat-form-field
          class="clickable users" appearance="outline"
          (click)="onUsersDropdownClick()"
        >
          <mat-label class="clickable">{{ translations.users }}</mat-label>
          <mat-select
            [formControl]="userControl"
            (openedChange)="userFilter.value = ''"
            panelClass="dropdown-panel"
            [multiple]="true"

          >
            <mat-form-field>
              <mat-label>{{ translations.user }}</mat-label>
              <input #userFilter matInput type="text" autocomplete="off" />
            </mat-form-field>
            <div class="scrollbar" *ngIf="filteredUsers&&filteredUsers.length">
              <ng-container *ngFor="let user of filteredUsers">
                <mat-option
                  [value]="user.id"
                  [hidden]="
                    !user
                      ?.displayName!.toLowerCase()
                      .includes(userFilter.value.toLowerCase())
                  "
                >
                  {{ user?.displayName! }}
                </mat-option>
              </ng-container>
            </div>
          </mat-select>
        </mat-form-field>
        </form>
    </div>
    <div class="total-hours col-md-6 text-end mr-3">
      <div class="px-3">
        Total Hours: {{totalAmountOfHours}}
      </div>
    </div>
  </div>
  <processdelight-calendar
      #kalender
      [calenderItems]="calenderItems"
      (dateChanged)="dateChanged($event)"
      (addCalenderItem)="addItem($event)"
      (calenderItemDragOrResize)="itemChangedByDragOrResize($event)"
      (viewChanged)="onViewChanged($event)"
      [hideTopBar]="hideTopBar"
      [allowItemCollision]="allowItemCollision"
      [contextBlockMenuActionsFn]="contextBlockMenuActionsFn"
      [contextItemMenuActionsFn]="contextItemMenuActionsFn"
      [customContextMenu]="true"
      [calendarHeight]="calendarHeight"
      [hourBlocks]="hourBlocks"
      [currentView]="currentView"
      [selectedDate]="navDate"
      [splitMultipleDayEventsWeekView]="true"
  ></processdelight-calendar>
</div>
