import { createAction, props } from "@ngrx/store";
import { TimeSort } from "../../domain/models/time-sort";
import { UserSkill } from "../../domain/models/user-skill";
import { Filter } from "processdelight-angular-components";

export const getUserSkills = createAction(
  '[userSkill] Get userSkills',
  props<{
    filters: Filter[];
    callback?: () => void;
  }>()
);

export const getUserSkillsResolved = createAction(
  '[userSkill] Get userSkills resolved',
  props<{
    result: UserSkill[];
  }>()
);

export const getTimeSorts = createAction(
  '[TimeSort] Get timeSorts',
  props<{
    callback?: () => void;
  }>()
);

export const getTimeSortsResolved = createAction(
  '[TimeSort] Get timeSorts resolved',
  props<{
    result: TimeSort[];
  }>()
);

export const removeTimeSorts = createAction(
  '[TimeSort] Remove timeSort',
  props<{
    ishtarTimeTimesortIds: string[];
    callback?: () => void
  }>()
);

export const removeTimeSortsResolved = createAction(
  '[TimeSort] Remove timeSort resolved',
  props<{ ishtarTimeTimesortIds: string[] }>()
);

export const updateTimeSort = createAction(
  '[TimeSort] Update TimeSort',
  props<{ timeSorts: TimeSort[]; callback?: (d: TimeSort[]) => void }>()
);

export const updateTimeSortResolved = createAction(
  '[TimeSort] Update timeSort resolved',
  props<{ updatedTimeSorts: TimeSort[] }>()
);

export const addTimeSort = createAction(
  '[TimeSort] Add timeSort',
  props<{ timeSorts: TimeSort[]; callback?: (d: TimeSort[]) => void }>()
);

export const addTimeSortResolved = createAction(
  '[TimeSort] Add timeSort resolved',
  props<{ addedTimeSorts: TimeSort[] }>()
);
