import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { DateTime } from 'luxon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  AADUser,
  DataSourceService,
  EntityObject,
  ProjectsPreloadSelectorComponent,
  SelectComponent,
  Task,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { NoSpaceDirective } from 'src/app/configuration/shared/directives/no-space.directive';
import { TimeInputDirective } from 'src/app/configuration/shared/directives/time-input.directive';
import {
  license$,
  timeSorts$,
  translations$,
} from 'src/app/core/data/data.observables';
import { Skill } from 'src/app/core/domain/models/skill.model';
import { TimeRegistration } from 'src/app/core/domain/models/time-registration.model';
import { TimeSort } from 'src/app/core/domain/models/time-sort';
import { GroupFacade } from 'src/app/core/store/group/group.facade';
import { TimeRegistrationFacade } from 'src/app/core/store/time-registration/time-registration.facade';
import { TimeSortFacade } from 'src/app/core/store/time-sort/time-sort.facade';
import { clear, Memoize } from 'typescript-memoize';
import { v4 } from 'uuid';
import { TaskType } from '../../core/domain/models/task-type.model';
import { TasksPreloadSelectorComponent } from './tasks-preload-selector/tasks-preload-selector.component';
import { StartEndFormBalancer } from 'src/app/core/utils/start-end-form.balancer';

export const SNACKBAR_DURATION_MS = 3000;

export class RegistrationDetailData {
  selectedUserIds!: string[];
  isManager?: boolean = false;
  users?: string[];
  copyMode?: boolean = false;
  timeRegistrationItem?: TimeRegistration;
  startDate!: DateTime;
  endDate!: DateTime;
  timeRegistrations!: TimeRegistration[];

  constructor(data: Partial<RegistrationDetailData>) {
    Object.assign(this, data);
  }
}

@Component({
  standalone: true,
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    TimeInputDirective,
    NoSpaceDirective,
    SelectComponent,
    ProjectsPreloadSelectorComponent,
    TasksPreloadSelectorComponent,
  ],
})
export class RegistrationDetailsComponent implements OnInit, OnDestroy {
  translations = translations$.value;
  license = license$.value;

  registrationForm!: FormGroup;
  timeRegistrations: TimeRegistration[] = [];

  timeSorts = timeSorts$.value;
  skills: Skill[] = [];

  destroy$ = new Subject<void>();

  selectedTimeRegistration?: TimeRegistration;
  editMode = false;
  copyMode = false;
  isLoading = false;
  selectedUsers$ = new BehaviorSubject<AADUser[]>([]);
  showUsersDropdown = false;

  dataSourceService = inject(DataSourceService);

  startDate!: DateTime;
  endDate!: DateTime;

  sortValueAccessor: (t: TimeSort) => string = (t) => t.id;
  sortDisplayValueAccessor: (t: TimeSort) => string = (t) => t.sort;
  userValueAccessor: (t: AADUser) => string = (t) => t.id;
  userDisplayValueAccessor: (u: AADUser) => string = (u) => u.displayName;

  get hasProjectsLicense() {
    return license$.value?.licenses.some(
      (l) => l.productName === 'Ishtar.Projects'
    );
  }

  get hasTasksLicense() {
    return license$.value?.licenses.some(
      (l) => l.productName === 'Ishtar.Tasks'
    );
  }

  private valueChangesSubscription = new Subscription();
  get userFormControl() {
    return this.registrationForm.get('users') as FormControl;
  }

  get userControlVal() {
    return this.userFormControl.value as string[];
  }

  get timeSortFormControl() {
    return this.registrationForm.get('timeSortId') as FormControl;
  }

  get timeSortControlVal() {
    return this.timeSortFormControl.value as string;
  }

  get typeFormControl() {
    return this.registrationForm.get('type') as FormControl;
  }

  get typeControlVal() {
    return this.typeFormControl.value as string;
  }

  get skillFormControl() {
    return this.registrationForm.get('skillId') as FormControl;
  }

  get skillControlVal() {
    return this.skillFormControl.value as string;
  }

  get projectFormControl() {
    return this.registrationForm.get('projectId') as FormControl;
  }

  get projectControlVal() {
    return this.projectFormControl.value[0] as string | undefined;
  }

  get taskFormControl() {
    return this.registrationForm.get('taskId') as FormControl;
  }

  get taskControlVal() {
    return this.taskFormControl.value as string;
  }

  constructor(
    private detailsDialogRef: MatDialogRef<RegistrationDetailsComponent>,
    private timeSortsFacade: TimeSortFacade,
    private timeRegistrationFacade: TimeRegistrationFacade,
    private _snackBar: MatSnackBar,
    public groupFacade: GroupFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: RegistrationDetailData
  ) {
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.timeRegistrations = data.timeRegistrations;

    if (data?.timeRegistrationItem) {
      this.selectedTimeRegistration = data.timeRegistrationItem;
      this.editMode = data?.copyMode ? false : true;
      this.copyMode = data?.copyMode ?? false;
    } else {
      this.endDate = this.endDate.set({
        hour: this.endDate.hour + 1,
      });
    }
  }

  ngOnInit() {
    combineLatest([
      this.timeSortsFacade.timeSorts$,
      this.timeSortsFacade.allSelectedCommonUsersSkills$(
        this.data.selectedUserIds
      ),
    ]).subscribe(([ts, us]) => {
      this.skills = us
        .flatMap((us) => us.skill)
        .map(
          (s) =>
            new Skill({
              ...s,
            })
        );
      this.timeSorts = ts?.filter((t) => {
        if ((t.skills?.length ?? 0) === 0) {
          return true;
        } else if (
          t.skills?.some((s) => this.skills.some((sk) => sk?.id === s.skillId))
        ) {
          return t.skills?.some((s) =>
            this.skills.some((sk) => sk?.id === s.skillId)
          );
        }
        return false;
      });
    });

    this.groupFacade.myUsers$.pipe(takeUntil(this.destroy$)).subscribe((u) => {
      this.selectedUsers$.next(
        u.map((u) => this.groupFacade.users.find((us) => us.id === u)!)
      );
      this.showUsersDropdown =
        (this.data.isManager ?? false) &&
        !this.editMode &&
        !this.copyMode &&
        u.length > 0;
    });

    this.initForm();

    if (this.selectedTimeRegistration?.timeSortId) {
      this.skillFormControl.setValue(
        this.selectedTimeRegistration?.skillId ?? null
      );
    }
  }

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  getTimeSortValue(timeSortId: string) {
    const timeSortValue = this.timeSorts?.find(
      (t) => timeSortId === t.id
    )?.sort;
    return this.getTranslation(timeSortValue);
  }

  getSkillValue(skillId: string) {
    const skillValue = this.skills?.find((s) => skillId === s.id)?.title;
    return (this.getTranslation(skillValue)?.length ?? 0) > 0
      ? this.getTranslation(skillValue)
      : skillValue;
  }

  getTranslation(label: string | undefined): string | undefined {
    if (label) {
      return translations$.value[label]?.length > 0
        ? translations$.value[label]
        : label;
    }
    return undefined;
  }

  onCloseDialog() {
    this.detailsDialogRef.close();
  }

  onSave() {
    this.registrationForm.markAllAsTouched();
    this.registrationForm.updateValueAndValidity();
    if (this.registrationForm.invalid) return;
    const errorOverlapMessage =
      this.getTranslation('timeRegistrationsOverlap') ??
      '(en) timeRegistrationsOverlap';

    const timeRegistrationsToAdd = this.userControlVal.flatMap((id) => [
      this.MapControlToTimeRegistration(id)!,
    ]);
    const overlaps = timeRegistrationsToAdd.some((t) =>
      this.hasOverlapWithExisting(
        t,
        this.timeRegistrations.filter((tr) => t === undefined || tr.id !== t.id)
      )
    );

    if (overlaps) {
      this._snackBar.open(errorOverlapMessage, 'X', {
        panelClass: 'app-notification-error',
      });
      return;
    }

    this.isLoading = true;

    if (!this.editMode) {
      this.registrationForm.disable({ emitEvent: false });
      this.timeRegistrationFacade
        .addTimeRegistration$(timeRegistrationsToAdd)
        .pipe(
          catchError((error) => {
            console.error(error);
            this._snackBar
              .open(error, 'X', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(SNACKBAR_DURATION_MS);
            this.registrationForm.enable({ emitEvent: false });
            throw error;
          })
        )
        .subscribe((addedTimeRegistrations) => {
          if (addedTimeRegistrations) {
            this.isLoading = false;
            this.detailsDialogRef.close(addedTimeRegistrations);
            this.showSuccessSnackbar('Time Registration created');
          }
        });
    } else {
      const timeRegistrationToEdit = this.MapControlToTimeRegistration(
        this.data.selectedUserIds?.[0] ?? ''
      );
      if (!timeRegistrationToEdit) return;
      this.registrationForm.disable({ emitEvent: false });
      this.timeRegistrationFacade
        .updateTimeRegistration$([timeRegistrationToEdit])
        .pipe(
          catchError((error) => {
            console.error(error);
            this._snackBar
              .open(error, 'X', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(SNACKBAR_DURATION_MS);
            this.registrationForm.enable({ emitEvent: false });
            throw error;
          })
        )
        .subscribe(() => {
          const timeRegistrationUpdatedMessage =
            this.getTranslation('timeRegistrationUpdated') ??
            '(en) timeRegistrationUpdated';
          this.isLoading = false;
          this.detailsDialogRef.close([timeRegistrationToEdit]);
          this.showSuccessSnackbar(timeRegistrationUpdatedMessage);
        });
    }
  }

  MapControlToTimeRegistration(userId: string) {
    const startTimeSplit = this.registrationForm
      .get('startTime')
      ?.value.split(':');
    const startDateTime = (<DateTime>(
      this.registrationForm.get('startDate')?.value
    )).set({
      hour: parseInt(startTimeSplit[0]),
      minute: parseInt(startTimeSplit[1]),
    });

    const endTimeSplit = this.registrationForm.get('endTime')?.value.split(':');
    const endDateTime = (<DateTime>(
      this.registrationForm.get('endDate')?.value
    )).set({
      hour: parseInt(endTimeSplit[0]),
      minute: parseInt(endTimeSplit[1]),
    });

    if (!(startDateTime && endDateTime && endDateTime > startDateTime)) {
      const errorStartDateBeforeEndDateMessage =
        this.getTranslation('endDateBeforeStartDate') ??
        '(en) endDateBeforeStartDate';
      this._snackBar
        .open(errorStartDateBeforeEndDateMessage, 'X', {
          panelClass: 'app-notification-error',
        })
        ._dismissAfter(SNACKBAR_DURATION_MS);
      this.isLoading = false;
      return;
    }

    return new TimeRegistration({
      id: this.editMode ? this.registrationForm.get('id')?.value : v4(),
      title: this.registrationForm.get('title')?.value,
      startDate: startDateTime,
      endDate: endDateTime,
      userId: userId,
      projectId: this.registrationForm.get('projectId')?.value[0],
      timeSortId: this.registrationForm.get('timeSortId')?.value,
      skillId: this.registrationForm.get('skillId')?.value
        ? this.skills.find(
            (s) => s.id === this.registrationForm.get('skillId')?.value
          )?.id
        : null,
      taskId: this.registrationForm.get('taskId')?.value[0],
    });
  }

  private hasOverlapWithExisting(
    otherTimeRegistration: TimeRegistration,
    existingTimeRegistrations: TimeRegistration[]
  ): boolean {
    return existingTimeRegistrations.some((existingRegistration) =>
      this.checkOverlap(existingRegistration, otherTimeRegistration)
    );
  }

  private checkOverlap(
    existingRegistration: TimeRegistration,
    otherTimeRegistration: TimeRegistration
  ): boolean {
    if (
      existingRegistration.startDate &&
      existingRegistration.endDate &&
      otherTimeRegistration.startDate &&
      otherTimeRegistration.endDate
    ) {
      const startDate1 = existingRegistration.startDate;
      const endDate1 = existingRegistration.endDate;
      const startDate2 = otherTimeRegistration.startDate;
      const endDate2 = otherTimeRegistration.endDate;

      if (startDate1 && endDate1) {
        return (
          (startDate2 > startDate1 && startDate2 < endDate1) ||
          (endDate2 > startDate1 && endDate2 < endDate1) ||
          (startDate1 > startDate2 && startDate1 < endDate2) ||
          (endDate1 > startDate2 && endDate1 < endDate2) ||
          (startDate2.valueOf() === startDate1.valueOf() &&
            endDate2.valueOf() === endDate1.valueOf())
        );
      }
    }

    return false;
  }

  private async showSuccessSnackbar(message: string) {
    await this._snackBar
      .open(message, 'Ok', {
        panelClass: 'app-notification-success',
      })
      ._dismissAfter(SNACKBAR_DURATION_MS);
  }

  private initForm(): void {
    const skillSort = !this.selectedTimeRegistration
      ? undefined
      : this.timeSorts?.find((s) =>
          s.skills?.some(
            (sk) => sk.skillId === this.selectedTimeRegistration?.skillId
          )
        );

    this.registrationForm = new FormGroup({
      id: new FormControl(this.selectedTimeRegistration?.id ?? v4()),
      title: new FormControl(
        {
          value: this.selectedTimeRegistration
            ? this.selectedTimeRegistration.title
            : '',
          disabled: false,
        },
        [Validators.required, Validators.maxLength(700)]
      ),
      startDate: new FormControl(
        {
          value: this.startDate,
          disabled: false,
        },
        Validators.required
      ),
      startTime: new FormControl(
        { value: this.startDate.toFormat('HH:mm'), disabled: false },
        Validators.required
      ),
      endDate: new FormControl(
        {
          value: this.endDate,
          disabled: false,
        },
        Validators.required
      ),
      endTime: new FormControl(
        { value: this.endDate.toFormat('HH:mm'), disabled: false },
        Validators.required
      ),
      timeSortId: new FormControl(
        {
          value: this.selectedTimeRegistration?.timeSortId ?? skillSort?.id,
          disabled: false,
        },
        Validators.required
      ),
      typeId: new FormControl({
        value: this.selectedTimeRegistration?.typeId,
        disabled: false,
      }),

      projectId: new FormControl({
        value: this.selectedTimeRegistration?.projectId
          ? [this.selectedTimeRegistration?.projectId]
          : [],
        disabled: false,
      }),
      taskId: new FormControl({
        value: this.selectedTimeRegistration?.taskId
          ? [this.selectedTimeRegistration?.taskId]
          : [],
        disabled: false,
      }),
      users: new FormControl(
        {
          value: this.data.selectedUserIds ? this.data.selectedUserIds : [],
          disabled: false,
        },
        Validators.required
      ),
    });

    StartEndFormBalancer(
      this.registrationForm.controls['startDate'] as FormControl<DateTime>,
      this.registrationForm.controls['endDate'] as FormControl<DateTime>,
      this.destroy$,
      this.registrationForm.controls['startTime'] as FormControl<string>,
      this.registrationForm.controls['endTime'] as FormControl<string>
    );

    this.registrationForm.controls['projectId'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        clear(['TIMEprojChanged']);
      });
  }

  @Memoize({ tags: ['TasksData'] })
  SelectedProjectHasTasks() {
    const data = this.dataSourceService.getData(
      'TasksData'
    ) as EntityObject<Task>[];
    try {
      return data?.some((t) => t.entity?.projectId === this.projectControlVal);
    } catch {
      console.log('unable to read tasksdata');
      return false;
    }
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
