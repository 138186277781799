import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { ApiService } from '../../services/api.service';
import {
  addTimeRegistration,
  addTimeRegistrationResolved,
  getTimeRegistrations,
  getTimeRegistrationsResolved,
  removeTimeRegistrations,
  removeTimeRegistrationsResolved,
  updateTimeRegistration,
  updateTimeRegistrationFailed,
  updateTimeRegistrationResolved,
} from './time-registration.actions';
import { timeRegistrationState } from './time-registration.reducer';

@Injectable({ providedIn: 'root' })
export class TimeRegistrationEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private _store: Store<AppState>
  ) {}

  getTimeRegistrations = createEffect(() =>
    this.actions$.pipe(
      ofType(getTimeRegistrations),
      withLatestFrom(
        this._store.pipe(
          select(timeRegistrationState),
          map((state) => state.timeRegistrations)
        ),
        this._store.pipe(
          select(timeRegistrationState),
          map((state) => state.loadTimeInterval)
        )
      ),
      switchMap(
        ([
          { userIds, loadTimeInterval, callback },
          timeRegs,
          cachedInterval,
        ]) => {
          if (
            !!cachedInterval &&
            cachedInterval.start < loadTimeInterval.start &&
            cachedInterval.end > loadTimeInterval.end
          )
            return of(
              getTimeRegistrationsResolved({
                result: timeRegs,
                loadTimeInterval,
              })
            );

          return this.apiService
            .getTimeRegistrations(userIds, loadTimeInterval)
            .pipe(
              switchMap((result) =>
                of(getTimeRegistrationsResolved({ result, loadTimeInterval }))
              ),
              tap(() => (callback ? callback() : undefined)),
              catchError((e) => [])
            );
        }
      )
    )
  );

  addTimeRegistration = createEffect(() =>
    this.actions$.pipe(
      ofType(addTimeRegistration),
      switchMap(({ timeRegistrations, callback }) =>
        this.apiService.addTimeRegistration(timeRegistrations).pipe(
          tap((timeRegistrations) =>
            callback ? callback(timeRegistrations) : undefined
          ),
          switchMap((addedTimeRegistrations) =>
            of(addTimeRegistrationResolved({ addedTimeRegistrations }))
          ),
          catchError((error) => [])
        )
      )
    )
  );

  updateTimeRegistration = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTimeRegistration),
      switchMap(({ timeRegistrations, callback }) =>
        this.apiService.updateTimeRegistration(timeRegistrations).pipe(
          tap((timeRegistrations) =>
            callback ? callback(timeRegistrations) : undefined
          ),
          switchMap((updatedTimeRegistrations) =>
            of(updateTimeRegistrationResolved({ updatedTimeRegistrations }))
          ),
          catchError((error) => of(updateTimeRegistrationFailed({ error })))
        )
      )
    )
  );

  removeTimeRegistration = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTimeRegistrations),
      switchMap(({ ishtarTimeRegistrationIds, callback }) =>
        this.apiService.removeTimeRegistrations(ishtarTimeRegistrationIds).pipe(
          switchMap(() =>
            of(removeTimeRegistrationsResolved({ ishtarTimeRegistrationIds }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
