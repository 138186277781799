<div class="dialogContainer">
  <h1 mat-dialog-title>{{translations.deleteTimeRegistration}}</h1>
  <mat-icon class="closeButtonIcon" (click)="onCloseDialog('close')"
    >close</mat-icon
  >
  <div
    mat-dialog-content
    class="p-3 d-flex flex-column justify-content-center align-items-center"
  >
    <p>
       {{translations.deleteTimeRegistrationNamed}}?
    </p>
    <p>
      {{data.timeRegistration.title}}
    </p>
    <div class="buttons">
      <button
        mat-raised-button
        class="menuButton"
        (click)="onCloseDialog('submit')"
      >
        <mat-icon class="marginRight">delete</mat-icon>
        <span>{{translations.delete}}</span>
      </button>
      <button
        mat-raised-button
        class="menuButton"
        color="primary"
        (click)="onCloseDialog('close')"
      >
        <mat-icon>close</mat-icon>
        <span>{{translations.cancel}}</span>
      </button>
    </div>
  </div>
</div>
