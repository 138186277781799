import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  catchError,
  filter,
  first,
  forkJoin,
  switchMap,
  tap,
} from 'rxjs';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import {
  Ishtar365CommunicationService,
  LoaderService,
  MicrosoftAuthenticationService,
  TranslationService,
} from 'processdelight-angular-components';
import { ApiService } from './api.service';
import {
  appName$,
  languages$,
  license$,
  projects$,
  publicHolidays$,
  tasks$,
  translations$
} from '../data/data.observables';
import { Router } from '@angular/router';
import { Language } from '../domain/models/language.model';
import { PublicHolidayModel } from '../domain/models/public-holiday.model';
import { DummyProjectFacade } from '../store/dummy-project/dummy-project.facade';
import { TimeSortFacade } from '../store/time-sort/time-sort.facade';

interface Observables {
  projects?: Observable<any>;
  dummyProjects?: Observable<any>;
  tasks?: Observable<any>;
  userSkills?: Observable<any>;
  timeSorts?: Observable<any>;
  translations?: Observable<any>;
  languages?: Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private api: ApiService,
    private loader: LoaderService,
    private router: Router,
    private dummyProjectFacade: DummyProjectFacade,
    private timeSortFacade: TimeSortFacade,
    private msal: MicrosoftAuthenticationService,
    private ishtarCommunicationsService: Ishtar365CommunicationService,
    private translationService: TranslationService
  ) {}

  getLicense(): Observable<UserLicenseInfo> {
    return this.loader.startLoading$('Retrieving license information', () =>
      this.api.getLicense(this.msal.tenantId)
        .pipe(
          tap((data) => license$.next(data)),
          catchError((error) => {
            this.router.navigate(['/401']);
            throw error;
          })
        )
    );
  }

   getTimeSorts() {
    return this.timeSortFacade.getTimeSorts$();
  }

  getProjects() {
    return this.api.getProjects();
  }

  getTasks() {
    return this.api.getTasks();
  }

  getLanguages(): Observable<Language[]> {
    return this.api.getLanguages()
      .pipe(tap((data) => languages$.next(data)));
  }

  getTranslations(lang: string) {
    this.translationService.setLocale(lang);
    return this.api.getTranslations(lang)
      .pipe(tap((data) => translations$.next(data)), tap((data) => this.translationService.update(data)));
  }

  getPublicHolidays(year: number) {
    return this.api.getPublicHolidays(year)
      .pipe(tap((data) => publicHolidays$.next(data)));
  }

  boot() {
    const currentYear = new Date().getFullYear();
    const startLoading = new Subject<void>();
    this.loader.startLoading('Loading initial data', () => startLoading);
    return this.getLicense().pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        document.documentElement.style.setProperty(
          '--nav-color',
          license?.navColor ?? '#fff'
        );
        document.documentElement.style.setProperty(
          '--nav-contrast',
          license?.navContrast ?? '#000'
        );
        const observables: Observables = {};

        if (!license?.licenses.some((l) => l.productName === 'Ishtar.Time')) {
          this.router.navigate(['/401']);
          throw new Error('No license found');
        }

        if (license?.licenses.some((l) => l.productName === 'Ishtar.Projects')) {
          observables.projects = this.getProjects();
        }
        if (license?.licenses.some((l) => l.productName === 'Ishtar.Tasks')) {
          observables['tasks'] = this.getTasks();
        }

        appName$.next('Ishtar.Time');

        try {
          this.ishtarCommunicationsService.init();
        } catch {
          // not in ishtar365
        }

        const publicHolidaysRequests = [
          this.getPublicHolidays(currentYear - 1),
          this.getPublicHolidays(currentYear),
          this.getPublicHolidays(currentYear + 1),
        ];

        observables.timeSorts = this.getTimeSorts();
        observables.translations = this.getTranslations(license$.value?.language || '')
          .pipe(
            filter((t) => !!Object.keys(t).length),
            first()
          );
        observables.languages = this.getLanguages();

        return forkJoin({
          ...observables,
          publicHolidays: forkJoin(publicHolidaysRequests),
        });
      }),
      tap((data) => {
        projects$.next(data.projects);
        tasks$.next(data.tasks);
        const publicHolidays: PublicHolidayModel[] = [];
        for (const yearData of data.publicHolidays) {
          for (const holiday of yearData) {
            publicHolidays.push(holiday);
          }
        }
        publicHolidays$.next(publicHolidays);
        startLoading.next();
        startLoading.complete();
      })
    );
  }
}
