import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, catchError, of, switchMap, tap } from "rxjs";
import { ApiService } from "../../services/api.service";
import { addTimeRegistration, addTimeRegistrationResolved, getTimeRegistrations, getTimeRegistrationsResolved, removeTimeRegistrations, removeTimeRegistrationsResolved, updateTimeRegistration, updateTimeRegistrationFailed, updateTimeRegistrationResolved } from "./time-registration.actions";

@Injectable({ providedIn: 'root' })
export class TimeRegistrationEffects {
    constructor(
        private actions$: Actions,
        private apiService: ApiService
    ) {}

    getTimeRegistrations = createEffect(() =>
        this.actions$.pipe(
            ofType(getTimeRegistrations),
            switchMap(({
                filters,
                loadTimeInterval,
                callback,
               }) =>
                this.apiService.getTimeRegistrations(filters,loadTimeInterval).pipe(
                    switchMap((result) =>
                         of(
                          getTimeRegistrationsResolved({result})
                       )
                    ),
                    tap(() => (callback ? callback() : undefined)),
                    catchError((e) => [])
                )
            )
        )
    );

    addTimeRegistration = createEffect(() =>
        this.actions$.pipe(
            ofType(addTimeRegistration),
            switchMap(({ timeRegistrations, callback }) =>
                this.apiService.addTimeRegistration(timeRegistrations).pipe(
                    tap((timeRegistrations) => (callback ? callback(timeRegistrations): undefined)),
                    switchMap((addedTimeRegistrations) =>
                        of(addTimeRegistrationResolved({ addedTimeRegistrations }))
                    ),
                    catchError((error) => [])
                )
            )
        )
    );

    updateTimeRegistration = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTimeRegistration),
            switchMap(({ timeRegistrations, callback }) =>
                this.apiService.updateTimeRegistration(timeRegistrations).pipe(
                    tap((timeRegistrations) => (callback ? callback(timeRegistrations): undefined)),
                    switchMap((updatedTimeRegistrations) =>
                        of(updateTimeRegistrationResolved({ updatedTimeRegistrations }))
                    ),
                    catchError((error) => of(updateTimeRegistrationFailed({ error })))
                )
            )
        )
    );

    removeTimeRegistration = createEffect(() =>
        this.actions$.pipe(
            ofType(removeTimeRegistrations),
            switchMap(({ ishtarTimeRegistrationIds, callback }) =>
                this.apiService.removeTimeRegistrations(ishtarTimeRegistrationIds).pipe(
                switchMap(() =>
                    of(removeTimeRegistrationsResolved({ishtarTimeRegistrationIds}))
                ),
                tap(() => (callback ? callback() : undefined)),
                catchError((e) => [])
                )
            )
        )
    );
}
