import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { combineLatest, pairwise, startWith, Subject, takeUntil } from 'rxjs';

export const StartEndFormBalancer = (
  startDateForm: FormControl<DateTime>,
  endDateForm: FormControl<DateTime>,
  destroy$: Subject<void>,
  startTimeForm?: FormControl<string>,
  endTimeForm?: FormControl<string>
) => {
  startDateForm.valueChanges
    .pipe(takeUntil(destroy$), startWith(startDateForm.value), pairwise())
    .subscribe(([startFormValue, start]) => {
      const interval = startFormValue.until(endDateForm.value);
      if (!interval.isValid || interval.length('days') == 0) return;
      const newEnd = start.plus(interval.toDuration());
      endDateForm.setValue(newEnd, { emitEvent: false });
    });

  if (startTimeForm && endTimeForm)
    startTimeForm.valueChanges
      .pipe(takeUntil(destroy$), startWith(startTimeForm.value), pairwise())
      .subscribe(([startFromValue, start]) => {
        const startTime = DateTime.fromFormat(start, 'HH:mm');
        const startExistingTime = DateTime.fromFormat(startFromValue, 'HH:mm');
        const endTime = DateTime.fromFormat(endTimeForm.value, 'HH:mm');
        const interval = startExistingTime.until(endTime);
        if (!interval.isValid || interval.length('minutes') == 0) return;
        const newEnd = startTime.plus(interval.toDuration());
        endTimeForm.setValue(newEnd.toFormat('HH:mm'), { emitEvent: false });
      });

  endDateForm.valueChanges
    .pipe(takeUntil(destroy$), startWith(endDateForm.value), pairwise())
    .subscribe(([endFormValue, end]) => {
      if (
        end.set({ hour: 0, minute: 0 }) <
        startDateForm.value.set({ hour: 0, minute: 0 })
      ) {
        const interval = startDateForm.value.until(endFormValue);
        if (!interval.isValid || interval.length('days') == 0) return;
        const newStart = end.minus(interval.toDuration());
        startDateForm.setValue(newStart, { emitEvent: false });
      }
    });

  if (startTimeForm && endTimeForm)
    endTimeForm.valueChanges
      .pipe(takeUntil(destroy$), startWith(endTimeForm.value), pairwise())
      .subscribe(([endFromValue, end]) => {
        if (end < startTimeForm.value) {
          const endTime = DateTime.fromFormat(end, 'HH:mm');
          const endExistingTime = DateTime.fromFormat(endFromValue, 'HH:mm');
          const startTime = DateTime.fromFormat(startTimeForm.value, 'HH:mm');
          const interval = startTime.until(endExistingTime);
          if (!interval.isValid || interval.length('minutes') == 0) return;
          const newStart = endTime.minus(interval.toDuration());
          startTimeForm.setValue(newStart.toFormat('HH:mm'), {
            emitEvent: false,
          });
        }
      });
};
