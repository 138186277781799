import { BaseEntity, Entity } from 'processdelight-angular-components';
import { Skill } from './skill.model';
import { TimeSortType } from '../enums/time-sort-type';

export class TimeSort extends Entity {
  sort!: string;
  color?: string;
  type!: TimeSortType;
  skills?: {
    skillId: string;
    timeSortId: string;
    skill: {
      id: string;
      title: string;
    };
  }[] = [];

  constructor(obj: Partial<TimeSort>) {
    super(obj);
    Object.assign(this, obj);
  }
}
