import { BaseEntity, GroupUser } from 'processdelight-angular-components';
import { Skill } from './skill.model';

export class UserSkill extends BaseEntity {
  user?: GroupUser;
  skill?: Skill;
  userId?:string
  skillId?:string

  constructor(obj: Partial<UserSkill>) {
    super(obj);
    Object.assign(this, obj);
  }
}
