import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Ishtar365CommunicationService,
  LoaderService,
  MicrosoftAuthenticationService,
  TranslationService,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  catchError,
  filter,
  first,
  forkJoin,
  switchMap,
  tap,
} from 'rxjs';
import {
  languages$,
  license$,
  publicHolidays$,
  translations$,
} from '../data/data.observables';
import { Language } from '../domain/models/language.model';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { GroupFacade } from '../store/group/group.facade';
import { TimeSortFacade } from '../store/time-sort/time-sort.facade';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StartUpService {
  constructor(
    private api: ApiService,
    private loader: LoaderService,
    private router: Router,
    private timeSortFacade: TimeSortFacade,
    private msal: MicrosoftAuthenticationService,
    private ishtarCommunicationsService: Ishtar365CommunicationService,
    private translationService: TranslationService,
    private groupFacade: GroupFacade
  ) {}

  getLicense(): Observable<UserLicenseInfo> {
    return this.loader.startLoading$('Retrieving license information', () =>
      this.api.getLicense(this.msal.tenantId).pipe(
        tap((data) => license$.next(data)),
        catchError((error) => {
          this.router.navigate(['/401']);
          throw error;
        })
      )
    );
  }

  getTimeSorts() {
    return this.timeSortFacade.getTimeSorts$();
  }

  getLanguages(): Observable<Language[]> {
    return this.api.getLanguages().pipe(tap((data) => languages$.next(data)));
  }

  getTranslations(lang: string) {
    this.translationService.setLocale(lang);
    return this.api.getTranslations(lang).pipe(
      tap((data) => translations$.next(data)),
      tap((data) => this.translationService.update(data))
    );
  }

  getPublicHolidays(year: number) {
    return this.api
      .getPublicHolidays(year)
      .pipe(tap((data) => publicHolidays$.next(data)));
  }

  boot() {
    const currentYear = new Date().getFullYear();
    const startLoading = new Subject<void>();
    this.loader.startLoading('Loading initial data', () => startLoading);
    return this.getLicense().pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        document.documentElement.style.setProperty(
          '--nav-color',
          license?.navColor ?? '#fff'
        );
        document.documentElement.style.setProperty(
          '--nav-contrast',
          license?.navContrast ?? '#000'
        );

        if (!license?.licenses.some((l) => l.productName === 'Ishtar.Time')) {
          this.router.navigate(['/401']);
          throw new Error('No license found');
        }

        try {
          this.ishtarCommunicationsService.init();
        } catch {
          // not in ishtar365
        }

        return forkJoin([
          this.getTimeSorts(),
          this.getTranslations(license.language || ''),
          this.getLanguages(),
          this.getPublicHolidays(currentYear - 1),
          this.getPublicHolidays(currentYear),
          this.getPublicHolidays(currentYear + 1),
          this.groupFacade.getGroups$(),
        ]);
      }),
      tap((data) => {
        startLoading.next();
        startLoading.complete();
      })
    );
  }
}
