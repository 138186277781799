<div class="dialogContainer">
  <div class="text-end titleDiv">
    <h1 mat-dialog-title class="col-8 text-center">
      {{
        editMode
          ? (getTranslation$("editRegistration") | async)
          : copyMode
          ? (getTranslation$("copyTimeRegistration") | async)
          : (getTranslation$("newRegistration") | async)
      }}
    </h1>
    <div class="d-flex justify-content-between align-items-center">
      <button
        mat-raised-button
        class="addButton"
        type="submit"
        (click)="onSave()"
        [disabled]="isLoading"
      >
        <mat-spinner
          *ngIf="isLoading"
          diameter="24"
          color="accent"
        ></mat-spinner>
        <span *ngIf="!isLoading" class="uppercase saveText">{{
          getTranslation$("save") | async
        }}</span>
      </button>
      <mat-icon class="closeButtonIcon" (click)="onCloseDialog()"
        >close</mat-icon
      >
    </div>
  </div>

  <div mat-dialog-content class="p-3 scrollbar" *ngIf="registrationForm">
    <form [formGroup]="registrationForm" (keydown.control.enter)="onSave()">
      <div class="row">
        <div class="col-12" *ngIf="showUsersDropdown">
          <processdelight-select
            class="w-100"
            formControlName="users"
            [options]="selectedUsers$ | async"
            [optionValueAccessor]="userValueAccessor"
            [optionDisplayValueAccessor]="userDisplayValueAccessor"
            [multiple]="true"
            [clearButton]="false"
          >
          </processdelight-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field
            appearance="outline"
            class="w-100 no-subscript-wrapper"
          >
            <mat-label>{{ getTranslation$("description") | async }}</mat-label>
            <textarea matInput formControlName="title" rows="4"></textarea>
            <mat-error
              *ngIf="registrationForm.controls['title'].errors?.['maxlength']"
              >{{ getTranslation$("shortenDescription") | async }}</mat-error
            >
            <mat-error
              *ngIf="registrationForm.controls['title'].errors?.['required']"
              >{{ getTranslation$("requiredToFillIn") | async }}</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-6">
          <mat-form-field
            appearance="outline"
            class="w-100 no-subscript-wrapper m-right"
          >
            <mat-label>{{ getTranslation$("startDate") | async }}</mat-label>
            <input
              matInput
              [matDatepicker]="startDatePicker"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-6">
          <mat-form-field
            appearance="outline"
            class="w-100 no-subscript-wrapper"
          >
            <mat-label>{{ getTranslation$("startTime") | async }}</mat-label>
            <input
              matInput
              [ngxTimepicker]="toggleStartTimepicker"
              [format]="24"
              formControlName="startTime"
              [disableClick]="true"
              appTimeInput
            />
            <ngx-material-timepicker-toggle
              matSuffix
              [for]="toggleStartTimepicker"
              class="timepicker-icon"
            ></ngx-material-timepicker-toggle>
            <ngx-material-timepicker
              [format]="24"
              #toggleStartTimepicker
              [confirmBtnTmpl]="confirmBtn"
              [cancelBtnTmpl]="cancelBtn"
            ></ngx-material-timepicker>
            <ng-template #cancelBtn>
              <button
                class="btn fontSize picker-button"
                mat-button
                style="margin-right: 10px"
              >
                {{ translations.cancel }}
              </button>
            </ng-template>
            <ng-template #confirmBtn>
              <button mat-button class="btn fontSize picker-button">
                {{ translations.okay }}
              </button>
            </ng-template>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-6">
          <mat-form-field
            appearance="outline"
            class="w-100 no-subscript-wrapper m-right"
          >
            <mat-label>{{ getTranslation$("endDate") | async }}</mat-label>
            <input
              matInput
              [matDatepicker]="endDatePicker"
              formControlName="endDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-6">
          <mat-form-field
            appearance="outline"
            class="w-100 no-subscript-wrapper"
          >
            <mat-label>{{ getTranslation$("endTime") | async }}</mat-label>
            <input
              matInput
              [ngxTimepicker]="toggleEndTimepicker"
              [format]="24"
              formControlName="endTime"
              [disableClick]="true"
              appTimeInput
            />
            <ngx-material-timepicker-toggle
              matSuffix
              [for]="toggleEndTimepicker"
              class="timepicker-icon"
            ></ngx-material-timepicker-toggle>
            <ngx-material-timepicker
              [format]="24"
              #toggleEndTimepicker
              [confirmBtnTmpl]="confirmBtn"
              [cancelBtnTmpl]="cancelBtn"
            ></ngx-material-timepicker>
            <ng-template #cancelBtn>
              <button
                class="btn fontSize picker-button"
                mat-button
                style="margin-right: 10px"
              >
                {{ translations.cancel }}
              </button>
            </ng-template>
            <ng-template #confirmBtn>
              <button mat-button class="btn fontSize picker-button">
                {{ translations.okay }}
              </button>
            </ng-template>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <processdelight-select
            class="w-100"
            formControlName="timeSortId"
            floatLabel="always"
            [options]="timeSorts"
            [optionValueAccessor]="sortValueAccessor"
            [optionDisplayValueAccessor]="sortDisplayValueAccessor"
            [multiple]="false"
            [label]="getTranslation$('activity') | async"
          >
          </processdelight-select>
        </div>
        <div class="col-md-6"></div>
      </div>

      <div class="row">
        <div class="col-md-6" *ngIf="hasProjectsLicense">
          <processdelight-projects-preload-selector
            class="w-100"
            [formControl]="projectFormControl"
            [label]="getTranslation$('project') | async"
            [isMultiple]="false"
          >
          </processdelight-projects-preload-selector>
        </div>

        <div class="col-md-6" *ngIf="hasTasksLicense">
          <app-tasks-preload-selector
            [label]="getTranslation$('task') | async"
            [formControl]="taskFormControl"
            [isMultiple]="false"
            [projectidFilter]="projectControlVal"
          >
          </app-tasks-preload-selector>
          <mat-hint></mat-hint>
        </div>
      </div>
    </form>
  </div>
</div>
