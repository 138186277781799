import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { TimeRegistration } from '../../domain/models/time-registration.model';
import {
  addTimeRegistrationResolved,
  getTimeRegistrationsResolved,
  removeTimeRegistrationsResolved,
  updateTimeRegistrationFailed,
  updateTimeRegistrationResolved,
} from './time-registration.actions';
import { LoadTimeInterval } from '../../domain/models/load-time-interval';

export const featureSlice = 'timeRegistration';

export interface State {
  timeRegistrations: TimeRegistration[];
  loadTimeInterval?: LoadTimeInterval;
  userIds: string[];
}

const defaultState: State = {
  timeRegistrations: [],
  userIds: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return timeRegistrationReducer(state, action);
}

export const initialState: State = defaultState;

export const timeRegistrationReducer = createReducer(
  initialState,
  on(getTimeRegistrationsResolved, (state, { result, loadTimeInterval }) => ({
    ...state,
    timeRegistrations: [...state.timeRegistrations, ...result].filter(
      (value, index, self) => self.findIndex((u) => u.id === value.id) === index
    ),
    loadTimeInterval: state.loadTimeInterval
      ? {
          start:
            !!state.loadTimeInterval &&
            loadTimeInterval.start < state.loadTimeInterval.start
              ? loadTimeInterval.start
              : state.loadTimeInterval.start,
          end:
            !!state.loadTimeInterval &&
            loadTimeInterval.end > state.loadTimeInterval.end
              ? loadTimeInterval.end
              : state.loadTimeInterval.end,
        }
      : loadTimeInterval,
  })),
  on(
    removeTimeRegistrationsResolved,
    (state, { ishtarTimeRegistrationIds }) => {
      return {
        ...state,
        timeRegistrations: state.timeRegistrations.filter(
          (t) => !ishtarTimeRegistrationIds.includes(t.id!)
        ),
      };
    }
  ),
  on(updateTimeRegistrationResolved, (state, { updatedTimeRegistrations }) => {
    return {
      ...state,
      timeRegistrations: state.timeRegistrations.map(
        (t) => updatedTimeRegistrations.find((s) => t.id === s.id) ?? t
      ),
    };
  }),
  on(updateTimeRegistrationFailed, (state, { error }) => ({
    ...state,
    error,
  })),
  on(addTimeRegistrationResolved, (state, { addedTimeRegistrations }) => {
    return {
      ...state,
      timeRegistrations: addedTimeRegistrations.concat([
        ...(state.timeRegistrations ?? []),
      ]),
    };
  })
);

export const timeRegistrationState = (state: AppState) =>
  state.coreFeature.timeRegistration;
